/* global __lodash__, _, moment, store, gapi,  FB, globalRouter, wrapGet, wrapPost, ArrayToMap, GetCategoryPath, ProcessItems, nativeGetTempSalerOrder, nativeSaveCarSession */
/* eslint-disable no-console */

const saveCarSession = nativeSaveCarSession;
//const saveCarSession = _.debounce(nativeSaveCarSession, 500, { 'trailing': true });

const getTempSalerOrder = nativeGetTempSalerOrder;
//const getTempSalerOrder = _.debounce(async (tempItems, payload)=> await nativeGetTempSalerOrder(tempItems, payload), 1000, { 'trailing': true });

let defaultState = {
    labeCustomerTaxRegType: 'NNaturaleza',
    orderQtyFormulaFields: [],
    useOrderQtyFormula: false,
    canShowInvoiceList: false,
    customsValues: {},
    showCommentSO: true,
    showCommentSt: true,
    lastOrderAddress: null,
    varietiesCache: {},
    addressSetting: null,
    useVariations: true,
    logoutCountDownMinutes: 45,
    showWelcomeMessage: false,
    useAllGenders: false,
    customerMapFields: null,
    customerTaxReg: [],
    customerIdtypes: [],
    maxItemInCar: 100000000,
    phonePattern: "(?:(?:00)?(?:\\+)?549?)?0?(?:11|[2368]\\d)(?:(?=\\d{0,2}15)\\d{2}(?:00))??\\d{8}$",
    scoringAceptation: 0.55,
    screenState: false,
    screenMsg: "",
    socketReady: false,
    appState: 'loading',
    redirectAfterLogin: null,
    pageOffset: 0,
    orderSelected: null,
    orderBy: 'Price',
    orderDirection: 'asc',
    language: 'es',
    defaultItemSize: "module",
    afterLoginPath: 'profile',
    isLoggin: false,
    mustUpdateInfo: false,
    translateDict: {},
    synonyms: [],
    itemsPerPage: 36,
    session: null,
    customer: null,
    customerGifts: [],
    suggestionList: [],
    salesman: null,
    orderTemplate: null,
    classifications: [],
    show_CarModal: false,
    setting: null,
    filters: [],
    filtersObjects: new Map(),
    carPreserveInfo: false,
    cartStatus: 'view',
    carOrderTemplate: null,
    itemUpdate: 0,
    itemTimeFlag: 0,
    itemsFullLoader: false,
    useFacebook: true,
    useTwitter: true,
    useInstragram: false,
    homeSections: [
        {PostionOrder: 0, SectionType: 'slider'},
        {PostionOrder: 1, Title: 'Ofertas', SectionType: 'itemList'},
        {PostionOrder: 2, Title: 'Más Vendidos', SectionType: 'itemList'},
        {PostionOrder: 3, Title: "Nuevos Lanzamientos", SectionType: 'itemList'}
    ],
    payTermList: [],
    paytermSuperchargeItemCode: null,
    discountItemCode: null,
    shipItemCode: null,
    shipModes: [],
    shipModeFlag: null,
    itemInHome: new Map(),
    items_in_map: new Map(),
    items_in_car: [],
    total_in_car: 0,
    items_store: new Map(),
    items: [],
    items_visible: [],
    priceDeal: null,
    DiscountDeal: null,
    favorites: [],
    favouritesOrders: [],
    carFlag: 0,
    cat_selected: null,
    cat_history: [],
    cat_history_temp: [],
    cat_list: [],
    cat_by_name: new Map(),
    cat_by_code: new Map(),
    open_categories: [],
    open_car_modal: false,
    orderView: "all",
    order_list: [],
    requireCenterCost: false,
    order_editable_states: [],
    order_Campaign: null,
    order_can_liberate: false,
    order_DelAddress: null,
    order_DelCity: null,
    order_DelProvinceCode: null,
    order_DelCountry: null,
    order_DelLocalityCode: null,
    order_DeliveryDate: moment().format('YYYY-MM-DD'),
    order_ShipMode: null,
    order_DelZipCode: null,
    order_Comment: null,
    order_PayTerm: null,
    order_DelDay: null,
    order_DelHour: null,
    order_Office: null,
    order_CenterCost: null,
    order_ReceptionManagerCode: null,
    order_CustPurchaseOrder: null,
    isAdmin: false,
    liberatorCodes: [],
    onlyModifiedCodes: [],
    CenterLiberators: [],
    CenterOnlyModifiedCodes: [],
    currentOrder: null,
    currentFavOrder: null,
    actionStack: [],
    mutationStack: [],
    vatCodeMap: new Map(),
    CustomerPersons: [],
    currentCustomerPerson: null,
    CustomerAddresses: [],
    selectLinkToOptions: {},
    OurSettingsCountry: null,
    daysByZone: {
        Monday: 0,
        Tuesday: 0,
        Wednesday: 0,
        Thursday: 0,
        Friday: 0,
        Saturday: 0,
        Sunday: 0
    },
    deliveryAddressConfirm: null,
    Avatar: null,
    autoLogout: null,
    giftCardItem: null,
    giftCardApplied: null,
    campaignApplied: null
};

// eslint-disable-next-line no-unused-vars
let defaultGetters = {
    getQtyFormulaFields: state => {
        return state.orderQtyFormulaFields;
    },
    canUseOrderQtyFormula: state => {
        return state.useOrderQtyFormula && state.orderQtyFormulaFields.length > 0;
    },
    canShowInvoiceListInProfile: state => {
        return state.canShowInvoiceList;
    },
    getCustomValue: state => varName => {
        return state.customsValues[varName];
    },
    getCategorySelectedPath: state => catCode => {
        let categoryPath = [];
        GetCategoryPath(catCode, state.cat_list[0], categoryPath);
        return categoryPath;
    },
    getPayTermSurchargeItemCode: (state) => {
        if (state.payTermList.length > 0 && state.carOrderTemplate !== null && _.has(state.carOrderTemplate, ['fields', 'PayTerm'])) {
            const payTermInOrder = state.payTermList.find(x => x.Code === state.carOrderTemplate.fields.PayTerm);
            if (payTermInOrder && payTermInOrder.SurchargeArtCode) {
                return payTermInOrder.SurchargeArtCode;
            }
        }
        return '';
    },
    getVairetiesFromCache: state => itemCode => {
        return state.varietiesCache[itemCode];
    },
    getMaxStockByCode: state => itemCode => {
        const giftCardItem = state.giftCardItem;
        if (giftCardItem && giftCardItem.fields.Code == itemCode)
            return -1;
        if (state.setting.ControlStockForSales) {
            const itemInStore = state.items_store.get(itemCode);
            if (itemInStore) {
                if (itemInStore.Reserved)
                    return itemInStore.Qty - itemInStore.Reserved;
                return itemInStore.Qty;
            }
        }
        if (state.setting && state.setting.MaxItemsInCar)
            return state.setting.MaxItemsInCar;
        return state.maxItemInCar;
    },
    getItemAmountInCar: state => itemCode => {
        const itemInCar = state.items_in_car.filter(x => x.id === itemCode);
        if (itemInCar.length == 1)
            return itemInCar[0].cant;
        return 0;
    },
    showItemImageInDetail: function (state) {
        if (state.setting)
            return state.setting.ShowItemImageInDetail;
        return false;
    },
    getGlobalCategories: function (state) {
        if (state.setting)
            return state.setting.HomeCategories.filter((x) => {
                return x.fields.isShowShopMenu;
            }).map(y => y.fields.CatCode);
        return [];
    },
    showCountryAddress: function (state) {
        if (state.setting)
            return state.setting.UseMultipleCountries;
        return false;
    },
    AddressRequiredInRegister: function (state) {
        if (state.setting)
            return state.setting.AddressRequiredInRegister;
        return false;
    },
    showSubTotalInOrderDetail: function (state) {
        if (state.setting)
            return state.setting.ShowSubTotalInOrderDetail && state.setting.ShowPriceWithIVA;
        return false;
    },
    showPayTermSurchargeInOrder: function (state) {
        if (state.setting)
            return state.setting.ShowPayTermSurchargeInOrderDetail;
        return false;
    },
    showIVAInOrder: function (state) {
        if (state.setting)
            return state.setting.ShowIVAInOrderDetail;
        return false;
    },
    showTaxInOrder: function (state) {
        if (state.setting)
            return state.setting.ShowTaxInOrderDetail;
        return false;
    },
    getWelcomeMessage: function (state) {
        if (state.setting && state.setting.WelcomePage)
            return state.showWelcomeMessage !== false && state.setting.WelcomePage;
        return false;
    },
    getCustomerExtraFields: state => customerType => {
        if (state.customerMapFields)
            return state.customerMapFields[customerType];
        return null;
    },
    useShipCost: (state) => {
        if (state.setting)
            return state.setting.CalculateDeliveryCost;
        return false;
    },
    useDiscountCoupon: (state) => {
        if (state.setting)
            return state.setting.UseDiscountCoupon;
        return false;
    },
    getDiscountItemCode: function (state) {
        return state.discountItemCode;
    },
    getShipItemCode: function (state) {
        return state.shipItemCode;
    },
    getShipModes: (state) => {
        return state.shipModes;
    },
    getAvatar: (state) => {
        return state.Avatar;
    },
    getAddressConfirmation: (state) => {
        return state.deliveryAddressConfirm;
    },
    getCustomerTaxReg: (state) => {
        return state.customerTaxReg;
    },
    getCustomerIdtypes: (state) => {
        return state.customerIdtypes;
    },
    useComplaintForm: (state) => {
        if (state.setting)
            return state.setting.ComplaintFormAction;
        return false;
    },
    getPhoneMaskPattern: (state) => {
        if (state.setting && state.setting.PhoneMaskValidator)
            return state.setting.PhoneMaskValidator;
        return state.phonePattern;
    },
    getMaxQtyItem: (state) => {
        if (state.setting && state.setting.MaxItemsInCar)
            return state.setting.MaxItemsInCar;
        return state.maxItemInCar;
    },
    getItemQtyCar: state => itemCode => {
        let carQty = 0;
        if (state.carOrderTemplate && _.has(state.carOrderTemplate, ['fields', 'Items'])) {
            for (let item of state.carOrderTemplate.fields.Items)
                if (item.fields.ArtCode === itemCode)
                    carQty += item.fields.Qty;
        } else if (state.items_in_car.length > 0) {
            for (let item of state.items_in_car) {
                if (item.id === itemCode)
                    carQty += item.cant;
            }
        }
        return carQty;
    },
    showZeroPrice: (state) => {
        if (state.setting)
            return !state.setting.ShowZeroPrice;
        return false;
    },
    getDaysByZone: (state) => {
        return state.daysByZone;
    },
    ScreenState: (state) => {
        return state.screenState;
    },
    getPayTerms: (state) => {
        if (state.setting)
            return state.setting.PayTermRow.map(x => x.fields.PayTermCode);
        return [];
    },
    getOnlinePayTerms: (state) => {
        if (state.setting)
            return state.setting.PayTermRow.filter(x => x.fields.RequireOnlinePayment).map(x => x.fields.PayTermCode);
        return [];
    },
    getHomeSections: (state) => {
        return state.homeSections;
    },
    getHomeSectionByCode: state => code => {
        return state.homeSections.filter(x => x.Code == code)[0];
    },
    showCategoryTree: (state) => {
        if (state.setting != null) {
            return state.setting.ShowCategoryTree;
        } else
            return true;
    },
    getItemsFullLoader: (state) => {
        return state.itemsFullLoader;
    },
    getOrderView: (state) => {
        return state.orderView;
    },
    getOrderComment: (state) => {
        return state.order_Comment;
    },
    getDelZipCode: (state) => {
        return state.order_DelZipCode;
    },
    getShipMode: (state) => {
        return state.order_ShipMode;
    },
    getDelCity: (state) => {
        return state.order_DelCity;
    },
    getDeliveryDate: (state) => {
        return state.order_DeliveryDate;
    },
    getDelProvince: (state) => {
        return state.order_DelProvinceCode;
    },
    getDelCountry: (state) => {
        return state.order_DelAddress;
    },
    getDelLocality: (state) => {
        return state.order_DelLocality;
    },
    getDelLocalityCode: (state) => {
        return state.order_DelLocalityCode;
    },
    getDelAddress: (state) => {
        return state.order_DelAddress;
    },
    getOrderSelected: (state) => {
        return state.orderSelected;
    },
    getMainColor: (state) => {
        if (state.setting && state.setting.MainColor) {
            return state.setting.MainColor;
        } else
            return '#293a56';
    },
    getControlStock: (state) => {
        if (state.setting) {
            return state.setting.ControlStockForSales;
        } else
            return false;
    },
    getSecundColor: (state) => {
        if (state.setting && state.setting.SecondColor) {
            return state.setting.SecondColor;
        } else
            return '#d85c81';
    },
    menuTitle: (state) => {
        if (state.setting && state.setting.MenuTitle) {
            return state.setting.MenuTitle;
        } else
            return "öppen";
    },
    menuSubTitle: (state) => {
        if (state.setting && state.setting.MenuSubTitle) {
            return state.setting.MenuSubTitle;
        } else
            return "ecommerce";
    },
    settingsIsLoaded: (state) => {
        return !!state.setting;
    },
    showStock: (state) => {
        if (state.setting)
            return state.setting.ShowStock;
        return false;
    },
    downloadItemsAttachs: (state) => {
        if (state.setting)
            return state.setting.DownloadItemsAttachs;
        return false;
    },
    getIsLogin: (state) => {
        return state.isLoggin;
    },
    getReports: (state) => {
        if (state.setting)
            return state.setting.ReportsRow.filter(x => !x.fields.isKPI);
        return [];
    },
    getIndicators: (state) => {
        if (state.setting)
            return state.setting.ReportsRow.filter(x => x.fields.isKPI);
        return [];
    },
    getReportParameters: state => reportName => {
        for (let report of state.setting.ReportsRow.filter(x => !x.fields.isKPI))
            if (report.fields.ReportName === reportName)
                return report.fields.reportSpec ? report.fields.reportSpec : null;
        return null;
    },
    getIndicatorParameters: state => reportName => {
        for (let report of state.setting.ReportsRow.filter(x => x.fields.isKPI))
            if (report.fields.ReportName === reportName)
                return report.fields.reportSpec ? report.fields.reportSpec : null;
        return null;
    },
    getReportTitle: state => reportName => {
        for (let report of state.setting.ReportsRow)
            if (report.fields.ReportName === reportName)
                return report.fields.ReportTitle;
        return 'Unknown Report';
    },
    getOrderBy: (state) => {
        return state.orderBy;
    },
    getOrderDirection: (state) => {
        return state.orderDirection;
    },
    getFilters: (state) => {
        return state.filters;
    },
    getOffset: (state) => {
        return state.pageOffset;
    },
    getItemPerPage: (state) => {
        return state.itemsPerPage;
    },
    getClassications: (state) => {
        return state.classifications;
    },
    getCurrentOrder: (state) => {
        return state.currentOrder;
    },
    getCurrentFavOrder: (state) => {
        return state.currentFavOrder;
    },
    getCurrentCustomerPerson: (state) => {
        return state.currentCustomerPerson;
    },
    getOrderList: (state) => {
        return state.order_list;
    },
    getOrderEditableStates: (state) => {
        return state.order_editable_states;
    },
    getAppState: (state) => {
        return state.appState;
    },
    getCustomerPersons: (state) => {
        return state.CustomerPersons;
    },
    getCustomerAddresses: (state) => {
        return state.CustomerAddresses;
    },
    getItemFilters: (state) => {
        if (state.setting && state.setting.ItemFilters)
            return state.setting.ItemFilters;
        else
            return [];
    },
    getHomeCategories: (state) => {
        if (state.setting)
            return state.setting.HomeCategories;
        else
            return [];
    },
    getHomeItems: (state) => {
        if (state.setting && state.setting.HomeItems)
            return state.setting.HomeItems;
        else
            return [];
    },
    showItemPrice: state => item => {
        let itemCode;
        if (item.id)
            itemCode = item.id;
        else
            itemCode = item.Code;
        let storeItem = state.items_store.get(itemCode);
        if (storeItem) {
            let price = storeItem.Price;
            if (state.setting.ShowPriceWithIVA)
                if (storeItem.RowTotal)
                    price = storeItem.RowTotal;
                else {
                    if (storeItem.RowNet)
                        price = storeItem.RowNet;
                }
            return parseFloat(Math.round(price * 100) / 100).toFixed(2);
        }
        return null;
    },
    getPriceToShow: (state) => {
        if (state.setting)
            return state.setting.ShowPriceWithIVA;
        else
            return false;
    },
    trSynonyms: state => value => {
        if (state.synonyms.length > 0)
            for (let synon of state.synonyms) {
                if (value.toLowerCase() === synon.Keys.toLowerCase())
                    return synon.ReplaceSet.toLowerCase();
            }
        return null;
    },
    trWithParrams: state => value => {
        let trApply = state.translateDict[value[0]];
        if (!trApply) {
            trApply = value[0];
        }

        for (let i = 1; i <= value.length; i++) {
            let subApplay = state.translateDict[value[i]] ? state.translateDict[value[i]] : value[i];
            trApply = trApply.replace(`{{$${i}}}`, subApplay);
        }
        return trApply;
    },
    tr: (state, getters) => value => {
        if (Array.isArray(value))
            return getters.trWithParrams(value);
        let trApply = state.translateDict[value];
        if (trApply)
            return trApply;
        return value;
    },
    getCategoryDataByName: state => name => {
        return state.cat_by_name.get(name);
    },
    getCategoryDataByCode: state => code => {
        return state.cat_by_code.get(code);
    },
    getopen_categories: (state) => {
        return state.open_categories;
    },
    getCarModal: (state) => {
        return state.show_CarModal;
    },
    getCarStatus: (state) => {
        return state.cartStatus;
    },
    getSetting: (state) => {
        return state.setting;
    },
    getSettingItemOrdered: (state) => {
        if (state.setting)
            return state.setting.ItemOrdered;
        else
            return [];
    },
    getCarAmount: (state) => {
        if (state.carOrderTemplate && state.carOrderTemplate.fields) {
            if (state.setting.ShowPriceWithIVA)
                return state.carOrderTemplate.fields.Total;
            return state.carOrderTemplate.fields.SubTotal;
        }
        let amount = 0;
        if (state.items_in_car.length > 0) {
            for (let item of state.items_in_car) {
                if (state.items_store.has(item.id)) {
                    amount += Math.abs(item.cant) * parseInt(state.items_store.get(item.id).Price);
                }
            }
        }
        return amount;
    },
    getSession: (state) => {
        return state.session;
    },
    getSalesMan: (state) => {
        return state.salesman;
    },
    getCustomer: (state) => {
        return state.customer;
    },
    getCustomerGifts: (state) => {
        //return state.customerGifts
        return __lodash__.orderBy(state.customerGifts, state.orderBy, state.orderDirection);
    },
    getSuggestions: (state) => {
        //return state.suggestionList;
        return __lodash__.orderBy(state.suggestionList, state.orderBy, state.orderDirection);
    },
    getOrderTemplate: (state) => {
        return state.orderTemplate;
    },
    getUserOnlyModifier: (state) => {
        return state.isOnlyModified;
    },
    getUserIsAdmin: (state) => {
        return state.isAdmin;
    },
    orderCanLiberate: (state) => {
        return state.order_can_liberate;
    },
    getItems: (state) => {
        return __lodash__.orderBy(state.items, state.orderBy, state.orderDirection);
    },
    getItemByCode: state => code => {
        return state.items_store.get(code);
    },
    getItemsVisibles: (state) => {
        return __lodash__.orderBy(state.items_visible, state.orderBy, state.orderDirection);
    },
    getCategories: (state) => {
        return state.cat_list;
    },
    getCategoriesMap: (state) => {
        return state.cat_map;
    },
    getCategorySelected: (state) => {
        return state.cat_selected;
    },
    getCategoriesHistory: (state) => {
        return state.cat_history;
    },
    getFavorites: (state) => {
        return state.favorites;
    },
    getFavoritesItems: (state) => {
        return state.favorites;
    },
    getItemCar: (state) => {
        return state.items_in_car;
    },
    getItemCarCount: (state) => {
        return state.items_in_car.length;
    },
    getItemCarAmount: (state) => {
        let carAmount = 0;
        state.items_in_car.map((item) => carAmount += item.cant * item.item.Price);
        return carAmount;
    },
    translate: state => text => {
        return state.translateDict[text] || text;
    },
    getAllFavouritesOrders: (state) => {
        return state.favouritesOrders;
    },
    getFavouriteOrderByName: state => name => {
        return state.favouritesOrders.filter(x => x[0] === name)[0];
    },
    getSelectLinkToArray: (state) => {
        return state.selectLinkToOptions;
    },
    getOurSettingsCountry: (state) => {
        return state.OurSettingsCountry;
    },
    getSelectLinkToValue: state => name => {
        if (Object.prototype.hasOwnProperty.call(state.selectLinkToOptions, name)) {
            return state.selectLinkToOptions[name];
        } else
            return "";
    },
    getCurrency: (state) => {
        if (state.setting) {
            if (state.setting.ShowCurrency) {
                if (state.setting.Currency)
                    return state.setting.Currency;
                else {
                    if (state.priceDeal.priceDeal)
                        return state.priceDeal.priceDeal.fields.Currency;
                    else
                        return false;
                }
            }
        }
        return false;
    },
    getGiftCardItem: (state) => {
        return state.giftCardItem;
    },
    getGiftCardApplied: (state) => {
        return state.giftCardApplied;
    },
    getCampaignApplied: (state) => {
        return state.campaignApplied;
    },
    useGiftcard: (state) => {
        return state.setting.UseGiftCard;
    }
};

// eslint-disable-next-line no-unused-vars
let defaultActions = {
    editOrderRow: ({state, commit}, updatedRow) => {
        const currentOrder = __lodash__.cloneDeep(state.currentOrder);
        let totalWithIVA = 0;
        let totalWithOutIVA = 0;
        for (let item of currentOrder.fields.Items) {
            if (item.fields.internalId === updatedRow.fields.internalId) {
                item.fields.Qty = updatedRow.fields.Qty;
            }
            item.fields.RowNet = item.fields.Price * item.fields.Qty;
            item.fields.RowTotal = item.fields.VATPrice * item.fields.Qty;
            totalWithIVA += item.fields.RowTotal;
            totalWithOutIVA += item.fields.RowNet;
        }
        currentOrder.fields.SubTotal = totalWithOutIVA;
        currentOrder.fields.Total = totalWithIVA;
        commit('updateCurrentOrder', currentOrder);
    },
    addVarietyToCar: ({commit, getters, state, dispatch}, item) => {
        if (!getters.canUseOrderQtyFormula)
            dispatch('showNotificacion', {
                title: `Agregaste ${item.carQty} ${item.carQty > 1 ? 'artículos' : 'artículo'}  a tu carrito`,
                message: `${item.Name}`,
                type: 'info'
            });
        commit('updateItemToCar', item);
        dispatch('loadOrderTotals', {});
    },
    loadVarieties:({commit,dispatch,getters,state},itemCode)=>{
        return new Promise((resolve, reject) => {
            if(!state.varietiesCache[itemCode])
                window.axios.get(`/ecommerce/api/getItemVarieties/${encodeURI(itemCode)}`).then(function (response) {
                    state.varietiesCache[itemCode] = response.data;
                    resolve(response.data);
                });
            else
                return state.varietiesCache[itemCode];
        });
    },
    loadPromotionInfo: ({commit, dispatch, getters, state}, filters) => {
        return new Promise((resolve, reject) => {
            wrapPost(`/${state.setting.PathURL}/api/loadPromotionData`, filters)
                .then(function (response) {
                    if (response.data.error) {
                        dispatch('showNotificacion', {
                            title: `Promotion`,
                            message: [response.data.error, "Promotion"],
                            type: 'warning'
                        });
                        resolve(response.data.images);
                    } else {
                        let itemsInStorage = [];
                        for (let item of response.data.items) {
                            if (state.items_store.has(item.Code))
                                itemsInStorage.push(state.items_store.get(item.Code));
                        }
                        if (itemsInStorage.length) {
                            dispatch('updatePrices', itemsInStorage).then(() => {
                                commit('updatesItemsList', itemsInStorage);
                                resolve(response.data.images);
                            });
                        } else {
                            resolve(response.data.images);
                        }
                    }
                })
                .catch(function (error) {
                    console.log('Error response in Load PaymentData', error);
                    reject(error);
                });
        });
    },
    changeCustomerTaxReg: ({commit}, customerTaxReg) => {
        commit('setCustomerTaxReg', customerTaxReg);
    },
    changeCustomerIdtypes: ({commit}, customerIdtypes) => {
        commit('setCustomerIdtypes', customerIdtypes);
    },
    loadPaymentData({state}, filters) {
        return new Promise((resolve, reject) => {
            wrapPost(`/${state.setting.PathURL}/api/loadPaymentData`, filters)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.log('Error response in Load PaymentData', error);
                    reject(error);
                });
        });
    },
    centerCostRequire({state, commit}, requireCenterCost) {
        commit('changeCenterCostRequire', requireCenterCost);
    },
    toogleLockScreen({state, commit}, lockMsg) {
        commit('updateLockScreen', {visible: !state.screenState, msg: lockMsg});
    },
    eventIOItem: function ({state, commit, dispatch}, event) {
        if (Number.isInteger(Number.parseInt(event.value)))
            dispatch('showNotificacion', {
                title: `Updating  Item`,
                message: `Item Number ${event.value}`,
                type: 'info'
            });
        else {
            dispatch('showNotificacion', {
                title: `Updating  Item`,
                message: `Item Number ${event.value.Code}`,
                type: 'info'
            });
        }
        commit('eventIOItem', event);
    },
    updatePricesBackGroup: async function ({commit, state, getters, dispatch}, itemList) {
        if ((state.setting && !state.setting.UpdatePriceInBackground) || !state.setting)
            return;
        const transformPrice = function (item) {
            let price = item.Price;
            if (store.getters.getPriceToShow)
                price = item.RowTotal;
            else {
                if (item.RowNet)
                    price = item.RowNet;
            }
            item.Price = parseFloat(Math.round((price + 0.00001) * 100) / 100);
        };

        let codes = [];
        //let orderItems =  __lodash__.orderBy( itemList, state.orderBy,state.orderDirection );
        for (let item of itemList) {
            let x = state.items_store.get(item.Code);
            if (x && x["isCalculate"] === false)
                codes.push(x.Code);
        }

        let PersonCode = state.session ? state.session.Code : null;
        let CustomerCode = state.customer ? state.customer.Code : null;
        let sendCodes = codes;

        if (sendCodes.length > 0) {
            let url = `/${state.setting.PathURL}/api/getPrices`;
            let step = 0;
            let sendCodesCounter = 0;
            let itemByRequest = 100;
            let itemToCommits = [];

            //let profilingTest = +uuidv4();
            while (sendCodesCounter < sendCodes.length) {
                let initStep = itemByRequest * step;
                let endStep = initStep + itemByRequest;
                let sendInRequest = sendCodes.slice(initStep, endStep);

                let response = await wrapPost(url, {
                    artCodes: sendInRequest,
                    personCode: PersonCode,
                    customerCode: CustomerCode
                });
                let responseParsed = response.data;
                for (let newX of sendInRequest) {
                    let x;
                    if (newX instanceof Object && newX.Code)
                        x = state.items_store.get(newX.Code);
                    else
                        x = state.items_store.get(newX);
                    if (x) {
                        x.isCalculate = true;
                        if (responseParsed[x.Code]) {
                            x.Price = responseParsed[x.Code].Price;
                            x.VATPrice = responseParsed[x.Code].VATPrice;
                            x.RowNet = responseParsed[x.Code].RowNet;
                            x.RowTotal = responseParsed[x.Code].RowTotal;
                            x.VATPrice = responseParsed[x.Code].VATPrice;
                            x.visible = true;
                            transformPrice(x);
                        }
                        itemToCommits.push(x);
                    } else {
                        dispatch('changeCartQty', {itemCode: newX, action: 'delete'});
                        console.log('Item sin precios investigar', newX.Code, newX);
                    }
                }
                step = step + 1;
                sendCodesCounter = sendCodesCounter + itemByRequest;
            }
            dispatch('updateItemsInStore', itemToCommits);
        }
    },
    updatePrices: async function ({commit, state, getters, dispatch}, itemList) {
        const transformPrice = function (item) {
            let price = item.Price;
            if (store.getters.getPriceToShow)
                price = item.RowTotal;
            else {
                if (item.RowNet)
                    price = item.RowNet;
            }
            item.Price = parseFloat(Math.round((price + 0.00001) * 100) / 100);
        };

        let codes = [];
        for (let item of itemList) {
            let x = state.items_store.get(item.Code);
            if (x && x["isCalculate"] === false)
                codes.push(x.Code);
        }

        let PersonCode = state.session ? state.session.Code : null;
        let CustomerCode = state.customer ? state.customer.Code : null;
        let sendCodes = codes;
        commit('updateAppState', 'loading');
        if (sendCodes.length > 0) {
            let url = `/${state.setting.PathURL}/api/getPrices`;
            let step = 0;
            let sendCodesCounter = 0;
            let itemByRequest = state.items_store.size / 2;
            let firstPriceCommit = true;
            let itemToCommits = [];

            if (state.orderBy !== "Price") {
                let fastCharge = [];
                for (let itemCode of sendCodes) {
                    let x = state.items_store.get(itemCode);
                    if (x) {
                        x.isCalculate = true;
                        x.visible = true;
                        fastCharge.push(x);
                    }
                }
                dispatch('updateItemsInStore', fastCharge);
            }
            while (sendCodesCounter < sendCodes.length) {
                let initStep = itemByRequest * step < sendCodes.length ? itemByRequest * step : sendCodes.length;
                let endStep = initStep + itemByRequest;
                let sendInRequest = sendCodes.slice(initStep, endStep);
                let response = await wrapPost(url, {
                    artCodes: sendInRequest,
                    personCode: PersonCode,
                    customerCode: CustomerCode
                });
                let responseParsed = response.data;
                for (let newX of sendInRequest) {
                    let x;
                    if (newX instanceof Object && newX.Code)
                        x = state.items_store.get(newX.Code);
                    else
                        x = state.items_store.get(newX);
                    if (x) {
                        x.isCalculate = true;
                        if (responseParsed[x.Code]) {
                            x.Price = responseParsed[x.Code].Price;
                            x.VATPrice = responseParsed[x.Code].VATPrice;
                            x.RowNet = responseParsed[x.Code].RowNet;
                            x.RowTotal = responseParsed[x.Code].RowTotal;
                            x.VATPrice = responseParsed[x.Code].VATPrice;
                            transformPrice(x);
                            x.visible = !((!x.Price || x.Price === 0) && state.setting.ShowZeroPrice);
                        }
                        //state.items_store.set(x.Code, x);
                        //commit('updateSingleItemStore',x);
                        itemToCommits.push(x);
                    } else {
                        console.log('Item sin precios investigar', newX.Code, newX);
                    }
                }
                if (state.orderBy !== "Price" && firstPriceCommit) {
                    await dispatch('updateItemsInStore', itemToCommits);
                    commit('updateItemFlag');
                    commit('updateAppState', 'fast-ready');
                    itemToCommits = [];
                    firstPriceCommit = false;
                }
                step = step + 1;
                sendCodesCounter = sendCodesCounter + itemByRequest;
            }
            dispatch('updateItemsInStore', itemToCommits);
        }
        commit('updateItemFlag');
        commit('updateAppState', 'ready');
        commit('updateCarAmount', getters);
    },
    updatePricesold: function ({commit, state, getters, dispatch}, newItems) {
        const transformPrice = function (item) {
            let price = item.Price;
            if (store.getters.getPriceToShow)
                price = item.RowTotal;
            else {
                if (item.RowNet)
                    price = item.RowNet;
            }
            item.Price = parseFloat(Math.round((price + 0.00001) * 100) / 100);
        };
        let codes = [];
        for (let x of newItems) {
            if (x && x["isCalculate"] === false && x['Code']) {
                codes.push(x.Code);
            }
        }
        let PersonCode = state.session ? state.session.Code : null;
        let CustomerCode = state.customer ? state.customer.Code : null;
        let sendCodes = codes;
        if (sendCodes.length > 0) {
            let url = `/${state.setting.PathURL}/api/getPrices`;
            let params = {artCodes: sendCodes, personCode: PersonCode, customerCode: CustomerCode};
            commit('updateAppState', 'loading');
            wrapPost(url, params)
                .then((response) => {
                    commit('updateAppState', 'loading');
                    let responseParsed = response.data;
                    for (let newX of newItems) {
                        let x = state.items_store.get(newX.Code);
                        if (responseParsed[x.Code]) {
                            x.Price = responseParsed[x.Code].Price;
                            x.VATPrice = responseParsed[x.Code].VATPrice;
                            x.RowNet = responseParsed[x.Code].RowNet;
                            x.RowTotal = responseParsed[x.Code].RowTotal;
                            x.VATPrice = responseParsed[x.Code].VATPrice;
                            x.isCalculate = true;
                            if (!x.Price && state.setting.ShowZeroPrice) {
                                if (!state.session)
                                    x.visible = false;
                                else
                                    x.visible = !!responseParsed[x.Code].IsPriceList;
                            } else
                                x.visible = true;
                            transformPrice(x);
                            state.items_store.set(x.Code, x);
                        }
                    }
                    commit('updateItemFlag');
                    commit('updateCarAmount', getters);
                    commit('updatesItemsList', newItems);
                    commit('updateAppState', 'ready');
                })
                .catch((err) => {
                    console.log('Error in getInitFetch', err);
                    commit('updateAppState', 'ready');
                    commit('updateItemFlag');
                });
        }
    },
    beforeChangeItems: function ({commit, state, getters, dispatch}, newItems) {
        //Resetear el listado de Artículos
        commit('updatesPageOffset', 0);
        dispatch('updatePrices', newItems).then(() => {
            commit('updatesItemsList', newItems);
            commit('updateItemFlag');
            commit('updateCarAmount', getters);
        });
    },
    addGenericSearch: function ({commit, state, dispatch}) {
        commit('clearFilterHides');
        commit('clearItemList');
        commit('updateAppState', 'loading');
        let newitems = [];
        let itemStoreList = Array.from(state.items_store.values());
        let maxPost = itemStoreList.length - 1;
        for (let x = 0; x < 24; x++) {
            let randPost = Math.floor(Math.random() * maxPost) + 1;
            newitems.push(itemStoreList[randPost]);
        }
        dispatch('beforeChangeItems', newitems);
    },
    addGlobalFilter: function ({commit, state, getters, dispatch}, filter) {
        commit('clearItemList');
        commit('restoreFilter');
        let newitems = [];
        for (let key of state.items_store.keys()) {
            let isPresent = RegExp(filter.label, 'gi');
            let x = state.items_store.get(key);
            if (state.useVariations && x.Father)
                continue;
            try {
                let isVisible = false;
                switch (filter.type) {
                    case "globalClassification":
                        isVisible = Object.prototype.hasOwnProperty.call(x.ClassificationPath, filter.label);
                        break;
                    case "inputSearch": {
                        x.SearchScore = 0;
                        isVisible = eval(filter.condition) === filter.result;
                        break;
                    }
                    case "rangeFilter":
                    case "genericFilter":
                        isVisible = eval(filter.condition) === filter.result;
                        break;
                    default:
                        if (x[filter.filterField] != null) {
                            isVisible = isPresent.test(x[filter.filterField]);
                        }
                        break;
                }
                if (isVisible) {
                    newitems.push(x);
                }
            } catch (err) {
                console.group(x.Code);
                console.log('Error filter item');
                console.log("Item", x);
                console.log('Filter', filter);
                console.log('Error', err);
                console.groupEnd(x.Code);
            }
        }
        if (filter.type === "inputSearch") {
            commit('setOrderBy', 'SearchScore');
            commit('setOrderDirection', 'desc');
        }

        if (filter.type === "globalClassification") {
            dispatch('updateCategory', getters.getCategoryDataByCode(filter.label));
        }
        dispatch('beforeChangeItems', newitems);
        commit('addFilter', filter);
    },
    addFilter: function ({commit, state, getters, dispatch}, filter) {
        if (!state.filtersObjects.has(filter.name)) {
            let filterItems = state.items;
            for (let x of filterItems) {
                if (x.visible === true) {
                    let isPresent = RegExp(filter.value, 'gi');
                    try {
                        switch (filter.type) {
                            case "classificationFilter":
                                isPresent = RegExp(filter.code, 'gi');
                                x.visible = isPresent.test(x[filter.filterField]);
                                break;
                            case  "valueFilter":
                                if (x.VariantionData) {
                                    try {
                                        x.visible = eval(filter.condition) === filter.result;
                                    } catch (err) {
                                        x.visible = false;
                                    }
                                }
                                break;
                            case  "rangeFilter":
                            case  "multivalue":
                                x.visible = eval(filter.condition) === filter.result;
                                break;
                            default:
                                if (x[filter.filterField] != null) {
                                    x.visible = isPresent.test(x[filter.filterField]);
                                }
                                break;
                        }
                    } catch (err) {
                        x.visible = false;
                    }
                    if (!x.visible && !filter.hiddens.has(x.Code)) {
                        filter.hiddens.set(x.Code, true);
                        x.HiddenFilters += 1;
                    }
                    if (state.setting.ShowZeroPrice) {
                        if (x.Price === 0 || !x.Price) {
                            filter.hiddens.set(x.Code, true);
                            x.visible = false;
                            x.HiddenFilters -= 1;
                        }
                    }
                }
            }
            commit('addFilter', filter);
            commit('updatesItemsList', filterItems);
            commit('updateItemFlag');
        } else {
            let filterItems = state.items;
            let storeFilter = state.filtersObjects.get(filter.name);
            for (let x of filterItems) {
                if (storeFilter.hiddens && storeFilter.hiddens.has(x.Code)) {
                    if (x.HiddenFilters > 0)
                        x.HiddenFilters -= 1;
                }
                if (state.setting.ShowZeroPrice) {
                    if (x.Price !== 0)
                        x.visible = x.HiddenFilters === 0;
                } else
                    x.visible = x.HiddenFilters === 0;
            }
            dispatch('removeFilter', filter.name);
            if (state.cat_selected && filter.type == 'globalClassification')
                dispatch('updateCategory', null);
            commit('updatesItemsList', filterItems);
            commit('updateItemFlag');
        }
    },
    newFilter: function ({commit, state, dispatch}, filter) {
        state.filters = [];
        state.filtersObjects = new Map();
        state.filters.push(filter[0]);
        state.filtersObjects.set(filter[0], filter[1]);

        let filters = [];
        for (let activeFilter of state.filtersObjects.values()) {
            filters = filters.concat(activeFilter);
        }
        dispatch('filterItem', {operator: 'or', filters: filters});
        commit('updateFilters', state.filters);
        commit('updateFiltersObjects', state.filtersObjects);
    },
    filterItem: function ({commit, state, dispatch}, params) {
        let newitems = [];
        for (let x of Array.from(state.items_store.values()))
            if (eval(params.filters[0].condition) === params.filters[0].result)
                newitems.push(x);
        if (newitems.length > 0)
            dispatch('beforeChangeItems', newitems);
    },
    restoreFilter: function ({commit}) {
        commit('restoreFilter');
        commit('updatesItemsList', []);
        commit('updateItemFlag');
    },
    removeFilter: function ({commit, state, dispatch}, filter) {
        console.log("removeFilter");
        state.filters = state.filters.filter(e => e !== filter);
        state.filtersObjects.delete(filter);
        commit('updateFilters', state.filters);
        commit('updateFiltersObjects', state.filtersObjects);
    },
    loadLanguage: async function ({state, commit}) {
        let response = await wrapGet(`${window.location.pathname}/api/getLanguage`);
        let languageDict = response.data;
        commit('setLanguage', languageDict.dict);
        if (languageDict.session) {
            commit('updateIsLogin', true);
            commit('updateSession', languageDict.session);
        }
    },
    loadAlias: function ({commit, state}, aliasCode) {
        return new Promise((resolve, reject) => {
            wrapPost(`/${state.setting.PathURL}/api/getFilterAlias`, {aliascode: aliasCode})
                .then(function (response) {
                    resolve(response.data.record);
                })
                .catch(function (error) {
                    console.log('Error response in Load Alias Filter ', error);
                    reject(error);
                });
        });
    },
    resetAutoLogout: function ({commit, state, dispatch}) {
        if (state.autoLogout)
            window.clearTimeout(state.autoLogout);
        let newTimeOut = window.setTimeout(() => {
            dispatch('logout');
        }, state.logoutCountDownMinutes * 60000);
        commit('resetAutoLogout', newTimeOut);
    },
    loadSession: async function ({commit, state, getters, dispatch}) {
        const location = window.location;
        const pathURL = `${location.pathname}/api/getInitFetch`;
        //debugger;
        let sessionPromise = new Promise((resolve, reject) => {
            commit('updateAppState', 'onload');
            wrapGet(pathURL)
                .then((response) => {
                    let objResponse = response.data;
                    commit('updateHomeSections',objResponse.homeSections);
                    commit('setCustomerMapFields',objResponse.customerMapFields);
                    commit('setCustomerTaxReg',objResponse.customerTaxReg);
                    commit('setCustomerIdtypes',objResponse.customerIdtypes);
                    commit('changeStoreValue',{stateField:"labeCustomerTaxRegType",value:objResponse.labeCustomerTaxRegType});
                    commit('changeStoreValue',{stateField:"canShowInvoiceList",value:objResponse.canShowInvoiceList});
                    commit('changeStoreValue',{stateField:"payTermList",value:objResponse.payTermList});
                    commit('changeStoreValue',{stateField:"customsValues",value:objResponse.CustomsValues});
                    commit('changeStoreValue',{stateField:"addressSetting",value:objResponse.addressSetting});
                    commit('changeStoreValue',{stateField:"useVariations",value:objResponse.useVariations});
                    commit('changeStoreValue',{stateField:"useAllGenders",value:objResponse.setting.fields.UseAllGenericGender === true});
                    commit('changeStoreValue',{stateField:"shipItemCode",value:objResponse.costItem});
                    commit('changeStoreValue',{stateField:"discountItemCode",value:objResponse.discountItem});
                    commit('changeStoreValue',{stateField:"showWelcomeMessage",value:objResponse.setting.fields.WelcomePage != null});
                    commit('changeStoreValue',{stateField:"orderQtyFormulaFields",value:objResponse.orderQtyFormulaFields});
                    commit('changeStoreValue',{stateField:"useOrderQtyFormula",value:objResponse.useOrderQtyFormula});
                    if(objResponse.setting.fields.MinSearchRate)
                        commit('changeStoreValue',{stateField:"scoringAceptation",value:objResponse.setting.fields.MinSearchRate});

                    if (objResponse.setting.fields.FilterQtyInView && objResponse.setting.fields.FilterQtyInView > 0)
                        commit('changeStoreValue', {
                            stateField: "itemsPerPage",
                            value: objResponse.setting.fields.FilterQtyInView
                        });

                    if (objResponse.items && objResponse.items.length > 0) {
                        commit('updatesItemsStore', ProcessItems(objResponse.items, state));
                    }

                    if (objResponse.sessionPerson) {
                        commit('updateIsLogin', true);
                        commit('updateSession', objResponse.sessionPerson);
                        commit('updateGenericUser', objResponse.sessionPerson.fields.RequireUserInfo);

                        if (state.items_in_car.length > 0) {
                            commit('updateSessionCar', state.items_in_car);
                            saveCarSession(state.appState, 'loadSession', JSON.stringify(state.items_in_car)).then(function (response) {
                                console.log("Throtelled SaveCarSession", response.data);
                            });
                        } else {
                            if (objResponse.sessionPerson.fields.CarInfo) {
                                let sessionCartItems = [];
                                for (let carItem of objResponse.sessionPerson.fields.CarInfo.items) {
                                    if (getters.canUseOrderQtyFormula) {
                                        carItem.item.carCant = null;
                                        carItem.message = null;
                                        sessionCartItems.push(carItem);
                                    } else {
                                        if (carItem.item && carItem.cant) {
                                            carItem.item.carCant = carItem.cant;
                                            carItem.message = null;
                                            sessionCartItems.push(carItem);
                                        }
                                    }

                                }
                                commit('updateSessionCar', sessionCartItems);

                            }
                        }
                        dispatch('resetAutoLogout');
                    }

                    commit('updateIsAdmin', objResponse.isAdmin);
                    commit('updateIsLiberatorOrder', objResponse.LiberatorCodes);
                    commit('updateIsOnlyModifier', objResponse.OnlyModifiedCodes);
                    commit('updateIsLiberatorCenterCost', objResponse.CenterLiberators);
                    commit('updateIsOnlyModifierCenterCost', objResponse.CenterOnlyModifiedCodes);
                    //console.log(objResponse.isAdmin, objResponse.isLiberator);

                    if (objResponse.customerGifts)
                        commit('updateGifts', objResponse.customerGifts);

                    if (objResponse.suggestions)
                        commit('updateSuggestions', objResponse.suggestions);

                    if (objResponse.classifications)
                        commit('updateCategoryMap', objResponse.classifications);

                    if (objResponse.classificationsTree)
                        commit('updatesCatList', objResponse.classificationsTree);

                    if (objResponse.setting)
                        commit('updateSetting', objResponse.setting.fields);

                    if (objResponse.customer)
                        commit('updateCustomer', objResponse.customer.fields);

                    if (objResponse.salesman)
                        commit('updateSalesMan', objResponse.salesman.fields);

                    if (objResponse.orderTemplate)
                        commit('updateOrderTemplate', objResponse.orderTemplate.fields);

                    if (objResponse.editStates)
                        commit('updateOrderEditableStates', objResponse.editStates);
                    if (objResponse.translateDict)
                        commit('updateTranslate', objResponse.translateDict);

                    if (objResponse.priceDeal)
                        commit('updatePriceDeal', objResponse.priceDeal);

                    if (objResponse.discountDeal)
                        commit('updateDiscountDeal', objResponse.discountDeal);

                    if (objResponse.favorites) {
                        let list = objResponse.favorites;
                        for (let item of list) {
                            commit('addFavorite', item);
                        }
                    }
                    if (objResponse.favouritesOrders) {
                        let favouritesOrders = objResponse.favouritesOrders;
                        for (let e of favouritesOrders) {
                            commit('addFavouritesOrders', e);
                        }
                    }

                    if (objResponse.vatCodeMap) {
                        commit('updateVatCode', objResponse.vatCodeMap);
                    }
                    if (objResponse.Avatar) {
                        commit('updateAvatar', objResponse.Avatar);
                    }

                    if (objResponse.Avatar) {
                        commit('updateAvatar', objResponse.Avatar);
                    }
                    commit('updateGiftCardItem', objResponse.GiftCardItem);

                    //Cargar el Pais de Opciones de Empresa
                    commit('updateOurSettingsCountry', objResponse.OurSettingsCountry);
                    commit('updateSelectLinkto', {table: 'Country', value: objResponse.OurSettingsCountry});
                    if (objResponse.fastMode) {
                        dispatch("loadInitItems");
                    } else {
                        commit('updateItemsIsLoaded', true);
                    }
                    commit('updateCarAmount', getters);
                    commit('updateAppState', 'ready');
                    resolve(true);
                });
        });

        return Promise.resolve(sessionPromise);
    },
    setRedirectLogin: async function ({commit}, redirectPath) {
        commit('updateRedirectLogin', redirectPath);
    },
    activePreserveCar: async function ({commit}, perserveStatus) {
        commit('updatePreserveStatus', perserveStatus);
    },
    reorderItems: function ({commit, state, dispatch}, fieldId) {
        for (let field of state.setting.ItemOrdered) {
            if (field.fields)
                field = field.fields;
            if (field.internalId === fieldId) {
                commit('setOrderSelected', field.internalId);
                commit('setOrderBy', field.ItemField);
                commit('setOrderDirection', field.OrderDirection);
                commit('updateItemFlag');
            }
        }
    },
    login: function ({commit, state, dispatch}, params) {
        let loginPromise = new Promise((resolve, reject) => {
            wrapPost(`/${state.setting.PathURL}/api/login`, {username: params.username, md5password: params.password})
                .then(function (response) {
                    let objResponse = response.data; //JSON.parse(response.data);
                    if (objResponse.ok) {
                        commit('updateItemsIsLoaded', false);
                        commit('updateIsLogin', true);
                        commit('updatesItemsStore', []);
                        dispatch('loadSession').then(() => {
                            resolve(objResponse);
                        });
                    } else
                        resolve(objResponse);
                })
                .catch(function (error) {
                    console.error(error);
                    resolve({ok: false, error: error});
                });
        });
        return Promise.resolve(loginPromise);
    },
    logout: function ({commit, state}) {
        wrapGet(`/${state.setting.PathURL}/api/logout`)
            .then(function (response) {
                try {
                    if (window.gapi) {
                        gapi.load('auth2', function () {
                            // Retrieve the singleton for the GoogleAuth library and set up the client.
                            gapi.auth2.init({client_id: '411860170731-ocuredme9dg0khkmc8pmudfv94hfrhp2.apps.googleusercontent.com'})
                                .then(function () {
                                    let a = gapi.auth2.getAuthInstance();
                                    a.disconnect();
                                });
                        });
                    }
                    if (window.FB)
                        FB.logout();
                } catch (error) {
                    console.error("API Log Out", error);
                }
                commit('resetState');
                document.location.href = `/${state.setting.PathURL}/`;
            })
            .catch(function (error) {
                console.log('error when try to close session', error);
            });
    },
    updateCategory: function ({commit, state}, category) {
        commit('updateCategory', category);
    },
    updateCategoryHistory: function ({commit, state}, parameters) {
        commit('updateCategoryHistory', parameters);
    },
    checkItemInCar: function ({dispatch, commit, state}) {
        let sessionCartItems = [];
        let needReload = false;
        for (let carItem of state.items_in_car) {
            if (state.items_store.has(carItem.id) || carItem.item.variant) {
                sessionCartItems.push(carItem);
            } else {
                needReload = true;
            }
        }
        commit('updateSessionCar', sessionCartItems);
        dispatch('loadOrderTotals', {needReload: needReload});
        dispatch('updateCarAmount', true);
    },
    loadInitItems: function ({dispatch, commit, state}, skipVisibility = false) {
        wrapGet(`/${state.setting.PathURL}/api/getItems`)
            .then(function (response) {
                let objResponse = response.data;

                if (objResponse.items && objResponse.items.length > 0) {
                    const processItems = ProcessItems(objResponse.items, state, skipVisibility);
                    commit('updatesItemsStore', processItems);
                    if (state.setting.ControlStockForSales) {
                        commit('checkStockQty');
                    }
                }
                commit('updateItemsIsLoaded', true);
                dispatch('checkItemInCar');
            })
            .catch(function (error) {
                console.error('Error response in loadItems', error);
            });
    },
    loadCategories: function ({commit, state}, root = null, lvl = 0) {
        wrapGet(`/${state.setting.PathURL}/api/getCategories`, {params: {root: root, lvl: lvl}})
            .then(function (response) {
                commit('updatesCatList', response);
            })
            .catch(function (error) {
                console.error('Error response in LoadCategories', error);
            });
    },
    restoreHistory: function ({commit, state}) {
        commit('restoreHistory');
    },
    toggleFavourite: function ({commit, dispatch, state}, itemCode) {
        wrapPost(`/${state.setting.PathURL}/api/favorite/` + itemCode, {favorite: true})
            .then(function (response) {
                if (response.data.resfav === "add" && response.data.saved === true)
                    commit('addFavorite', itemCode);
                if (response.data.resfav === "remove" && response.data.saved === true)
                    commit('removeFavorite', itemCode);
            })
            .catch(function (error) {
                console.error(error);
            });
    },
    toggleFavouriteOrder: function ({commit, dispatch, state}, FavOrder) {
        wrapPost(`/${state.setting.PathURL}/api/favouriteOrder/`, {FavOrder: FavOrder})
            .then(function (response) {
                if (response.data.resfav === "add" && response.data.saved === true)
                    commit('addFavouritesOrders', [FavOrder.name, FavOrder.items, FavOrder.OrderSerNr]);
                else
                    commit('removeFavouritesOrders', [FavOrder.name, FavOrder.items, FavOrder.OrderSerNr]);
            })
            .catch(function (error) {
                console.error(error);
            });
    },
    loadItems: function ({commit, state, dispatch}, category = '') {
        if (category != '') {
            wrapGet(`/${state.setting.PathURL}/api/getItems`, {
                params: {action: 'load', cat: category, offset: state.pageOffset, items_per_page: state.items}
            })
                .then(function (response) {
                    let items = JSON.parse(response);
                    commit('updatesItemsList', items);
                })
                .catch(function (error) {
                    console.error('Error response in LoadCategories', error);
                });
        } else
            dispatch('searchItems');
    },
    ItemComponentAddToCar: function ({commit, getters, state, dispatch}, item) {
        if (getters.canUseOrderQtyFormula)
            dispatch('addToCarWithQtyFormula', item);
        else {
            let newItemQty = parseInt(item.carQty);
            if (state.setting.ControlStockForSales) {
                const maxQty = getters.getMaxStockByCode(item.Code);
                if (maxQty == 0) {
                    newItemQty = maxQty;
                    dispatch('showNotificacion', {
                        title: `Stock insuficiente`,
                        message: ["This item {{$1}} is not longer available", item.Name],
                        type: 'error'
                    });
                    return;
                } else {
                    const carItemAmount = getters.getItemAmountInCar(item.Code);
                    const available = maxQty - carItemAmount;
                    if (carItemAmount > maxQty) {
                        dispatch('changeCartQty', {itemCode: item.Code, qty: maxQty, action: 'update'});
                        dispatch('loadOrderTotals', {});
                        dispatch('showNotificacion', {
                            title: `Stock insuficiente`,
                            message: ["The quantity of {{$1}} in cart was change from {{$2}} to {{$3}}", item.Name, carItemAmount, maxQty],
                            type: 'error'
                        });
                        return;
                    } else {
                        if (carItemAmount == maxQty) {
                            dispatch('showNotificacion', {
                                title: `Stock insuficiente`,
                                message: ["The max qty {{$1}} of article {{$2}} is already in cart", maxQty, item.Name],
                                type: 'error'
                            });
                            return;
                        }
                        if (newItemQty > available) {
                            dispatch('showNotificacion', {
                                title: `Stock insuficiente`,
                                message: ["The article qty can not be bigger than {{$1}}", available],
                                type: 'error'
                            });
                            return;
                        }
                    }
                }
            }
            dispatch('showNotificacion', {
                title: `Agregaste ${item.carQty} ${item.carQty > 1 ? 'artículos' : 'artículo'} a tu carrito`,
                message: `${item.Name}`,
                type: 'info'
            });
            commit('updateItemToCar', item);
            dispatch('loadOrderTotals', {});
        }
    },
    addToCarWithQtyFormula: function ({commit, getters, state, dispatch}, item) {
        dispatch('showNotificacion', {
            title: `Agregaste ${item.carQty} ${item.carQty > 1 ? 'artículos' : 'artículo'} a tu carrito`,
            message: `${item.Name}`,
            type: 'info'
        });
        commit('updateItemToCar', item);
        dispatch('loadOrderTotals', {});
    },
    ItemGiftcardAddToCar: function ({commit, state, getters, dispatch}, barCode) {
        wrapPost(`/${state.setting.PathURL}/api/giftcard`, {'barCode': barCode, 'cust': state.customer})
            .then(function (response) {
                let res = response.data;
                // commit('updateGiftCardApplied',res.record);
                if (res.res) {
                    dispatch('showNotificacion', {
                        title: getters.tr(`Added Giftcard`),
                        message: getters.tr(`Amount`) + " $" + res.amount,
                        type: 'info'
                    });
                    let giftCardItem = getters.getGiftCardItem;
                    let amount = res.amount;
                    if (amount > state.total_in_car)
                        amount = state.total_in_car;
                    let item = {
                        Code: giftCardItem.fields.Code,
                        Comment: giftCardItem.fields.Comment,
                        Name: giftCardItem.fields.Name,
                        carQty: 20,
                        giftcardCode: res.giftCardCode,
                        variant: true,
                        amount: amount
                    };
                    state.items_in_map.set(item.Code, {
                        id: item.Code,
                        cant: -1,
                        item: item,
                        message: null,
                        variant: true,
                        giftcardCode: res.giftCardCode,
                        giftcardNoShipCost: res.record.fields.NoShipCost,
                        amount: amount
                    });
                    state.items_in_car = Array.from(state.items_in_map.values());
                    state.carFlag = Math.floor((Math.random() * 1000000) + 1);
                    wrapPost(`/${state.setting.PathURL}/api/saveCarSession`, {
                        state: state.appState,
                        from: 'ItemGiftcardAddToCar',
                        items: JSON.stringify(state.items_in_car)
                    })
                        .then(function (response) {
                            dispatch('loadOrderTotals', {});
                            commit('updateCarAmount', getters);
                        })
                        .catch(function (error) {
                            console.error(error);
                        });
                } else
                    dispatch('showNotificacion', {
                        title: getters.tr(`Information`),
                        message: getters.tr("Invalid Giftcard Code"),
                        type: 'warning'
                    });
            })
            .catch(function (error) {
                console.error("Giftcard error:", error);
            });
    },
    clearItemToCar: function ({commit, dispatch, state}) {
        commit('clearItemToCar', 'view');
        dispatch('loadOrderTotals', {});
    },
    setOrderView: function ({commit}, newState) {
        commit('updateOrderView', newState);
    },
    approvedOrder: function ({state}, SerNr) {
        return new Promise((resolve, reject) => {
            wrapPost(`/${state.setting.PathURL}/api/approvedOrder`, {
                orderid: SerNr,
            })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    createSaleOrder: function ({commit, dispatch, state}, payload) {
        let tempItems = [];
        for (let item of state.items_in_car) {
            let carRow = {
                Code: item.item.Code,
                Cant: item.cant,
                giftcardCode: item.giftcardCode,
                giftcardNoShipCost: item.giftcardNoShipCost,
                amount: item.amount
            };
            for (let formulaField of state.orderQtyFormulaFields) {
                carRow[formulaField] = item[formulaField];
            }
            tempItems.push(carRow);
        }
        if (!payload)
            payload = {};
        if (!payload['Comment'])
            payload['Comment'] = state.order_Comment;

        if (!payload['Comment'])
            payload['Comment'] = state.order_Comment;

        if (state.order_DelAddressCode != null)
            payload['DelAddressCode'] = state.order_DelAddressCode;
        if (state.order_DelDay != null)
            payload['DelDay'] = state.order_DelDay;
        if (state.order_DelHour != null)
            payload['DelHour'] = state.order_DelHour;
        if (state.order_ReceptionManagerCode != null)
            payload['ReceptionManagerCode'] = state.order_ReceptionManagerCode;
        if (state.order_CustPurchaseOrder != null)
            payload['CustPurchaseOrder'] = state.order_CustPurchaseOrder;
        if (state.order_PayTerm != null)
            payload['PayTerm'] = state.order_PayTerm;
        if (state.order_CenterCost != null)
            payload['CenterCost'] = state.order_CenterCost;
        if (state.order_Office != null)
            payload['Office'] = state.order_Office;
        if (state.order_ShipMode != null)
            payload['ShipMode'] = state.order_ShipMode;
        return new Promise((resolve, reject) => {
            wrapPost(`/${state.setting.PathURL}/api/createSalesOrder`, {
                items: tempItems,
                extras: payload
            })
                .catch(function (error) {
                    commit('clearItemToCar', 'failure');
                    reject(error);
                })
                .then(function (response) {
                    // Se recargar los articulos para poder tener un stock mas actualizado
                    if (response.data.success) {
                        dispatch("loadInitItems", true);
                    }
                    resolve(response.data);
                });
        });
    },
    loadOrderTotals: function ({commit, state}, payload) {
        let needReload = false;
        if (payload.needReload)
            needReload = payload.needReload;
        else {
            if (!payload.needReload && (!state.carOrderTemplate || !state.carOrderTemplate.fields)) {
                needReload = true;
            } else {
                if (!needReload) {
                    for (let itemInCar of state.items_in_car) {
                        let soItem = state.carOrderTemplate.fields.Items.filter(x => itemInCar.id === x.fields.ArtCode)[0];
                        if (!soItem) {
                            needReload = true;
                            break;
                        }
                        if (itemInCar.cant != soItem.fields.Qty) {
                            needReload = true;
                            break;
                        }
                    }
                }
            }
        }

        if (needReload) {
            return new Promise((resolve, reject) => {
                let tempItems = [];
                for (let item of state.items_in_car) {
                    commit('updateGiftCardApplied', {Code: item.giftcardCode, NoShipCost: item.giftcardNoShipCost});
                    let carRow = {
                        Code: item.item.Code,
                        Cant: item.cant,
                        giftcardCode: item.giftcardCode,
                        giftcardNoShipCost: item.giftcardNoShipCost,
                        amount: item.amount
                    };
                    for (let formulaField of state.orderQtyFormulaFields) {
                        carRow[formulaField] = item[formulaField];
                    }
                    tempItems.push(carRow);
                }
                if (!payload)
                    payload = {};
                payload['QtyFormulaFields'] = state.orderQtyFormulaFields;
                payload['DelAddressCode'] = state.order_DelAddressCode;
                payload['Comment'] = state.order_Comment;
                payload['DelDate'] = state.order_DelDate;
                payload['ReceptionManagerCode'] = state.order_ReceptionManagerCode;
                payload['CustPurchaseOrder'] = state.order_CustPurchaseOrder;
                if (state.order_PayTerm)
                    payload['PayTerm'] = state.order_PayTerm;
                if (state.order_CenterCost)
                    payload['CenterCost'] = state.order_CenterCost;
                if (state.order_ShipMode)
                    payload['ShipMode'] = state.order_ShipMode;
                if (state.order_Campaign)
                    payload['Campaign'] = state.order_Campaign;
                if (state.order_InvoiceToCode)
                    payload['InvoiceToCode'] = state.order_InvoiceToCode;

                getTempSalerOrder(tempItems, payload)
                    .then((response) => {
                        if (response.data.shopModesAvailable && Array.isArray(response.data.shopModesAvailable) && state.lastOrderAddress !== state.order_DelAddressCode) {
                            commit('changeStoreValue', {
                                stateField: "lastOrderAddress",
                                value: state.order_DelAddressCode
                            });
                            commit('updateCarShipModes', response.data.shopModesAvailable);
                        }
                        commit("checkStockQty");
                        commit('updateCarOrderTemplate', Object.assign({}, response.data.so));
                        resolve(Object.assign({}, response.data.so));
                    })
                    .catch(function (error) {
                        resolve(null);
                    });
            });
        } else
            return state.carOrderTemplate;
    },
    loadOrderHistory: async function ({commit, state}, filter) {
        return new Promise((resolve, reject) => {
            wrapGet(`/${state.setting.PathURL}/api/getOrders`, {params: {filter: filter}})
                .then(function (response) {
                    //console.log(response.data);
                    commit('updateOrderEditableStates', response.data.editStates);
                    commit('updateOrderList', response.data.list);
                    resolve(true);
                }).catch(function (error) {
                //console.log('Error response in LoadSaleOrders',error);
                    resolve(false);
                });
        });
    },
    orderDetail: function ({commit, state}, orderid) {
        if (!orderid)
            return false;
        return new Promise((resolve, reject) => {
            wrapGet(`/${state.setting.PathURL}/api/getOrderDetail`, {params: {orderid: orderid}})
                .then(function (response) {
                    commit('updateCurrentOrder', response.data.order);
                    //commit('updateIsLiberatorOrder', response.data.isLiberator);
                    resolve(response.data);
                }).catch(function (error) {
                //console.log('Error response in LoadSaleOrders',error);
                    resolve(false);
                });
        });
    },
    favOrderDetail: function ({commit, state}, nameFav) {
        let favOrder = state.favouritesOrders.filter(x => x[0] === nameFav)[0];
        if (favOrder)
            commit('updateCurrentFavOrder', favOrder);
    },
    setCurrentCustomerPerson: function ({commit, state}, personCode) {
        let person = state.CustomerPersons.filter(x => x.Code === personCode)[0];

        if (person)
            commit('updateCurrentCustomerPerson', person);
    },
    copyOrder: function ({commit, state, dispatch, getters}, params) {
        if (!params.orderId)
            return false;
        const getMaxStockByCode = (itemCode) => {
            let giftCardItem = getters.getGiftCardItem;
            if (giftCardItem && giftCardItem.fields.Code == itemCode)
                return -1;
            if (getters.getSetting.ControlStockForSales) {
                let itemInStore = state.items_store.get(itemCode);
                if (itemInStore) {
                    if (itemInStore.Reserved)
                        return itemInStore.Qty - itemInStore.Reserved;
                    return itemInStore.Qty;
                }
            }
            return getters.getMaxQtyItem;
        };
        return new Promise((resolve) => {
            wrapGet(`/${state.setting.PathURL}/api/getOrderDetail`, {params: {orderid: params.orderId}})
                .then(function (response) {
                    if (Object.keys(response.data.order).length > 0) {
                        let itemList = [];
                        for (let item of response.data.order.fields.Items) {
                            let itemStore = state.items_store.get(item.fields.ArtCode);
                            if (!itemStore)
                                itemStore = item.fields;
                            if (state.setting.ControlStockForSales) {
                                let newItemQty = item.fields.Qty;
                                const maxQty = getMaxStockByCode(item.fields.ArtCode);
                                if (maxQty == 0) {
                                    response.data.order.fields.Items = response.data.order.fields.Items.filter(x => x.fields.ArtCode != item.fields.ArtCode);
                                    dispatch('changeCartQty', {itemCode: item.fields.ArtCode, action: 'delete'});
                                    dispatch('showNotificacion', {
                                        title: `Stock insuficiente`,
                                        message: ["This item {{$1}} is not longer available", item.Name],
                                        type: 'warning'
                                    });
                                    continue;
                                }
                                if (newItemQty > maxQty) {
                                    dispatch('showNotificacion', {
                                        title: `Stock insuficiente`,
                                        message: ["The article qty can not be bigger than {{$1}}", maxQty],
                                        type: 'warning'
                                    });
                                    newItemQty = maxQty;
                                }
                                itemStore['carQty'] = newItemQty;
                            } else {
                                itemStore['carQty'] = item.fields.Qty;
                            }
                            if (itemStore)
                                itemList.push(itemStore);
                        }
                        commit('clearItemToCar');
                        dispatch('updatePrices', itemList).then(function () {
                            commit('updateCurrentOrder', response.data.order);
                        });
                        for (let item of itemList) {
                            if (item.Code != state.discountItemCode && item.Code != state.shipCostItemCode) {
                                dispatch('showNotificacion', {
                                    title: `Agregaste ${item.carQty} artículos a tu carrito`,
                                    message: `${item.Name}`,
                                    type: 'info'
                                });
                                commit('updateItemToCar', item);
                            }
                        }
                        dispatch('loadOrderTotals', {});
                        resolve(true);
                    } else {
                        dispatch('showNotificacion', {
                            title: `System Message`,
                            message: `You are try to view/copy a order that are not created for you or your CustomerGroup`,
                            type: 'error'
                        });
                    }
                })
                .catch(function (error) {
                    console.log('Error response in LoadSaleOrders', error);
                    resolve(false);
                });
        });
    },
    orderAction: function ({commit, state, dispatch, getters}, params) {
        return new Promise((resolve) => {
            wrapPost(`/${state.setting.PathURL}/api/orderAction`, params)
                .then(async function (response) {
                    response = response.data;
                    if (response.success) {
                        /*if(params.action=="reject") {
                            let newOrderList = [];
                            for (let order of state.order_list)
                                if (order.SerNr != params.sernr)
                                    newOrderList.push(order);
                            commit('updateOrderList', newOrderList);
                        }*/
                        await dispatch('showNotificacion', {
                            title: `System Message`,
                            message: getters.tr(response.message),
                            type: 'info'
                        });
                        await dispatch('loadOrderHistory');
                        resolve(true);
                    } else {
                        if (response.message) {
                            dispatch('showNotificacion', {
                                title: `System Message`,
                                message: getters.tr(response.message),
                                type: 'error'
                            });
                        } else
                            dispatch('showNotificacion', {
                                title: `System Message`,
                                message: getters.tr(`The action you are trying to take could not be processed Please try again or please contact your account executive`),
                                type: 'error'
                            });
                    }
                    resolve(false);
                })
                .catch(function (error) {
                    console.log('Error response in OrderAction', error);
                    resolve(false);
                });

        });

    },
    changeCartQty: function ({commit, getters, state, dispatch}, params) {
        commit('updateCarQty', params);
        commit('updateCarAmount', getters);
        commit('updateCarFlag');
    },
    reloadCarPrice: function ({commit, getters, state, dispatch}, clearCodes) {
        commit('reloadCarPrice', clearCodes);
        commit('updateCarAmount', getters);
    },
    saveCurrentOrder: function ({commit, state, getters}) {
        return new Promise((resolve, reject) => {
            let params = {};
            params.sernr = state.currentOrder.fields.SerNr;
            params.items = {};
            if (state.currentOrder.fields)
                for (let item of state.currentOrder.fields.Items)
                    params.items[item.fields.internalId] = {
                        ArtCode: item.fields.ArtCode,
                        Qty: item.fields.Qty,
                        internalId: item.fields.internalId
                    };
            params.newItems = [];
            if (state.currentOrder.fields.NewItems)
                params.newItems = state.currentOrder.fields.NewItems;
            if (state.currentOrder.fields.NewItems)
                params.newItems = state.currentOrder.fields.NewItems;
            let salesmanText = "";
            if (state.salesman)
                salesmanText = `${state.salesman.Name} ${state.salesman.LastName} (${state.salesman.Phone}) (${state.salesman.Email})`;
            wrapPost(`/${state.setting.PathURL}/api/saveOrder`, params)
                .then(function (response) {
                    response = response.data;
                    if (response.success) {
                        commit('updateCurrentOrder', response.so);
                        // eslint-disable-next-line no-alert
                        alert(getters.tr(['ECOMMERCE_ORDER_MODIFIED', params.sernr, salesmanText ? ", " + salesmanText : '']));
                        //alert(`La orden de venta ${params.sernr} ha sido "MODIFICADA Y GUARDADA". Favor recordar que la misma deberá ser aprobada para ser procesada. Ante cualquier duda, favor de comunicarse con su ejecutivo de cuentas ${salesmanText ? ", " + salesmanText : ''}.`);
                    } else {
                        if (response.message) {
                            let errorMSG = ``;
                            for (let msg of response.message)
                                errorMSG += getters.tr(msg) + "\n";
                            // eslint-disable-next-line no-alert
                            alert(errorMSG);
                        } else {
                            if (response.error) {
                                let errorMSG = ``;
                                for (let msg of response.error)
                                    errorMSG += getters.tr(msg) + "\n";
                                // eslint-disable-next-line no-alert
                                alert(errorMSG);
                            } else {
                                // eslint-disable-next-line no-alert
                                alert(`La orden de venta ${params.sernr} no pudo ser "MODIFICADA O GUARDADA". Favor de comunicarse con su ejecutivo de cuentas ${", " + salesmanText}.`);
                            }
                        }
                    }
                    resolve(response);
                })
                .catch(function (error) {
                    resolve(error);
                });
        });
    },
    deleteItemCurrenOrder: function ({commit, state, dispatch}, item) {
        let currentOrder = Object.assign({}, state.currentOrder);
        for (let i = 0; i < currentOrder.fields.Items.length; i++) {
            if (currentOrder.fields.Items[i].fields.internalId === item.fields.internalId) {
                currentOrder.fields.Items.splice(i, 1);
                break;
            }
        }
    },
    updateUserInfo: function ({commit, state, dispatch}, params) {
        return new Promise((resolve, reject) => {
            wrapPost(`/${state.setting.PathURL}/api/updatePerson`, params)
                .then(function (response) {
                    response = response.data;
                    dispatch('updateGenericUser', !response.success);
                    dispatch('updateSession', response.person);
                    resolve(response);
                })
                .catch(function (error) {
                    console.log('Error response in Update UserInfo', error);
                    dispatch('updateGenericUser', false);
                    resolve(error);
                });
        });
    },
    sendToCarFavouriteOrder: function ({commit, state, dispatch}, params) {
        commit('clearItemToCar');
        let itemList = [];
        for (let item of params.FavouriteOrder[1]) {
            let itemStore = state.items_store.get(item[0]);
            if (!itemStore)
                itemStore = item;
            itemStore['carCant'] = item[1];
            itemList.push(itemStore);
        }
        dispatch('updatePrices', itemList);
        for (let item of itemList) {
            dispatch('ItemComponentAddToCar', item);
        }

    },
    loadCustomerPersons: function ({commit, state, dispatch}, params) {
        commit('clearCustomerPersons');
        wrapGet(`/${state.setting.PathURL}/api/getPersonsAssociated`)
            .then(function (response) {
                if (response.data.ok) {
                    commit('loadCustomerPersons', response.data.persons);
                }
            });
    },
    AddCustomerPerson: function ({commit, state, dispatch}, params) {
        commit('addCustomerPerson', params);
    },
    updateSelectLinkto: function ({commit, state, dispatch}, params) {
        commit('updateSelectLinkto', params);
    },
    updateCustomerPerson: function ({commit, state, dispatch}, params) {
        commit('updateCustomerPerson', params);
    },
    loadCustomerAddresses: function ({commit, state, dispatch}, params) {
        commit('clearCustomerAddresses');
        return new Promise((resolve, reject) => {
            let pathUrl = `/${state.setting.PathURL}/api/getDeliveryAddress`;
            if (state.order_InvoiceToCode)
                pathUrl = `${pathUrl}?forceCode=${state.order_InvoiceToCode}`;
            wrapGet(pathUrl)
                .then(async function (response) {
                    let CustomerAddresses = [];
                    if (response.data.deliveryAddress.length > 0) {
                        let obj = {};
                        for (let a of response.data.deliveryAddress) {
                            obj = {};
                            obj.Code = a.Code;
                            obj.Name = a.Name;
                            obj.Address = a.Address;
                            obj.City = a.City;
                            obj.ZipCode = a.ZipCode;
                            obj.ProvinceCode = a.ProvinceCode;
                            obj.Province = a.Province;
                            obj.LocalityCode = a.LocalityCode;
                            obj.Locality = a.Locality;
                            obj.Country = a.Country;
                            obj.Street = a.Street;
                            obj.StreetNumber = a.StreetNumber;
                            obj.Floor = a.Floor;
                            obj.Apartment = a.Apartment;
                            obj.Latitude = a.Latitude;
                            obj.Longitude = a.Longitude;
                            obj.normalize = !a.Latitude || !a.Longitude;
                            obj.CustomLocation = a.CustomLocation;
                            CustomerAddresses.push(obj);
                        }
                        commit('loadCustomerAddresses', CustomerAddresses);
                    }
                    resolve(CustomerAddresses);
                });
        });
    },
    AddAddressCustomer: function ({commit, state, dispatch}, params) {
        return new Promise((resolve, reject) => {
            params['EntityType'] = state.setting.UseAddresByPerson ? 2 : 0;
            params['Entity'] = state.setting.UseAddresByPerson ? state.session.fields.Code : state.customer.Code;
            params['EntityName'] = state.setting.UseAddresByPerson ? state.session.fields.Name : state.customer.Name;

            wrapPost(`/${state.setting.PathURL}/api/saveRecord`, {
                recordName: 'CompanyAddress',
                payload: params
            })
                .then(async function (response) {
                    if (response.data.success) {
                        let obj = {};
                        obj.Code = response.data.record.fields.Code;
                        obj.Name = response.data.record.fields.Name;
                        obj.Address = response.data.record.fields.Address;
                        obj.City = response.data.record.fields.City;
                        obj.ZipCode = response.data.record.fields.ZipCode;
                        obj.ProvinceCode = response.data.record.fields.ProvinceCode;
                        obj.Province = response.data.record.fields.Province;
                        obj.Country = response.data.record.fields.Country;
                        obj.Locality = response.data.record.fields.Locality;
                        obj.LocalityCode = response.data.record.fields.LocalityCode;
                        obj.Latitude = response.data.record.fields.Latitude;
                        obj.Longitude = response.data.record.fields.Longitude;
                        obj.normalize = !response.data.record.fields.Latitude || !response.data.record.fields.Longitude;
                        obj.CustomLocation = response.data.record.CustomLocation;
                        dispatch('showNotificacion', {
                            title: '',
                            message: "New address added",
                            type: 'success'
                        });
                        commit('AddAddressCustomer', obj);
                    }
                    resolve(response.data);
                });
        });
    },
    editAddressCustomer: function ({commit, state, dispatch}, params) {
        return new Promise((resolve, reject) => {
            wrapPost(`/${state.setting.PathURL}/api/updateAddress`, {
                address: params,
            })
                .then(async function (response) {
                    if (response.data.success) {
                        let obj = {};
                        obj.Code = response.data.record.fields.Code;
                        obj.Name = response.data.record.fields.Name;
                        obj.Address = response.data.record.fields.Address;
                        obj.City = response.data.record.fields.City;
                        obj.ZipCode = response.data.record.fields.ZipCode;
                        obj.ProvinceCode = response.data.record.fields.ProvinceCode;
                        obj.Province = response.data.record.fields.Province;
                        obj.Country = response.data.record.fields.Country;
                        obj.Locality = response.data.record.fields.Locality;
                        obj.LocalityCode = response.data.record.fields.LocalityCode;
                        obj.CountryName = response.data.record.fields.Country;
                        obj.Street = response.data.record.fields.Street;
                        obj.StreetNumber = response.data.record.fields.StreetNumber;
                        obj.Floor = response.data.record.fields.Floor;
                        obj.Apartment = response.data.record.fields.Apartment;
                        obj.Latitude = response.data.record.fields.Latitude;
                        obj.Longitude = response.data.record.fields.Longitude;
                        obj.normalize = !response.data.record.fields.Latitude || !response.data.record.fields.Longitude;
                        obj.CustomLocation = response.data.record.CustomLocation;
                        if (params._need_normalize)
                            commit("setDeliveryAddressConfirmation", obj);
                        else
                            dispatch('showNotificacion', {title: '', message: `Direccion guardada`, type: 'success'});
                        commit('updateAddressCustomer', obj);
                        resolve(obj);
                    } else
                        resolve(response.data.success);
                });
        });
    },
    disableAddress: function ({commit, state, dispatch}, Address) {
        wrapPost(`/${state.setting.PathURL}/api/disableAddress`, {AddressCode: Address.Code})
            .then(function (response) {
                if (response.data.success) {
                    dispatch('showNotificacion', {
                        title: '',
                        message: `Registro direccion ${Address.Code} cerrado`,
                        type: 'success'
                    });
                    commit('DisableAddress', Address.Code);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    clearSelectsLinkto: function ({commit, state, dispatch}) {
        commit('updateSelectLinkto', {table: 'Department', value: ""});
        commit('updateSelectLinkto', {table: 'JobPosition', value: ""});
        commit('updateSelectLinkto', {table: 'WebAccessGroup', value: ""});
        commit('updateSelectLinkto', {table: 'Country', value: ""});
        commit('updateSelectLinkto', {table: 'City', value: ""});
    },
    ChangeActivationCustomerPerson: function ({commit, state, dispatch}, personCode) {
        wrapPost(`/${state.setting.PathURL}/api/changeActivationCustomerPerson`, {personCode: personCode})
            .then(function (response) {
                if (response.data.success)
                    commit('updateCustomerPerson', response.data.person);
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    updateOrderDeliveryData: function ({commit, state, dispatch}, delData) {
        commit('updateOrderDeliveryData', delData);
    },
    updateOrderCenterCost: function ({commit, state, dispatch}, centerCost) {
        commit('updateOrderCenterCost', centerCost.Code);
    },
    showNotificacion: function ({commit, state, dispatch}, Notificacion) {
        window.$Event.$emit('toast', Notificacion);
    },
    loadZoneFromZipCode: function ({commit, state, dispatch}, ZipCode) {
        wrapPost(`/${state.setting.PathURL}/api/getDaysByZipCode`, {zipCode: ZipCode})
            .then(function (response) {
                let daysByZone = defaultState.daysByZone;
                if (response.data.zone) {
                    daysByZone = {
                        Friday: response.data.zone.Friday,
                        Monday: response.data.zone.Monday,
                        Saturday: response.data.zone.Saturday,
                        Sunday: response.data.zone.Sunday,
                        Thursday: response.data.zone.Thursday,
                        Tuesday: response.data.zone.Tuesday,
                        Wednesday: response.data.zone.Wednesday,
                    };
                }
                console.log('MyZone', daysByZone);
                commit('updateDaysByZone', daysByZone);
            })
            .catch(function (error) {
                commit('updateDaysByZone', defaultState.daysByZone);
            });
    },
    updateAvatar: function ({commit, state, dispatch}, params) {
        commit('updateAvatar', params);
    },
};

// eslint-disable-next-line no-unused-vars
let defaultMutation = {
    resetAutoLogout: (state, autoLogout) => {
        state.resetAutoLogout = autoLogout;
    },
    updateCarShipModes: (state, shipModes) => {
        state.shipModes = shipModes;
        state.shipModeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updateAvatar: (state, url) => {
        state.Avatar = url;
    },
    setDeliveryAddressConfirmation: (state, deliveryAddressConfirm) => {
        state.deliveryAddressConfirm = __lodash__.cloneDeep(deliveryAddressConfirm);
    },
    setCustomerMapFields(state, customerMapField) {
        state.customerMapFields = Object.freeze(customerMapField);
    },
    setCustomerTaxReg: (state, customerTaxReg) => {
        state.customerTaxReg = customerTaxReg;
    },
    setCustomerIdtypes: (state, customerIdtypes) => {
        state.customerIdtypes = customerIdtypes;
    },
    updateCarFlag(state) {
        state.carFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updateCarOrderTemplate(state, newCarOrderTemplate) {
        state.carOrderTemplate = newCarOrderTemplate;
    },
    changeStoreValue(state, changeObj) {
        state[changeObj.stateField] = changeObj.value;
    },
    changeAfterLogin(state, afterLoginPath) {
        state.afterLoginPath = afterLoginPath;
    },
    checkStockQty(state) {
        if (state.setting.ControlStockForSales) {
            let needUpdateUi = false;
            for (let [key, value] of state.items_in_map.entries()) {
                let giftCardItem = store.getters.getGiftCardItem;
                if (giftCardItem && giftCardItem.fields.Code != key) {
                    let itemStore = state.items_store.get(value.id);
                    if (!itemStore || value.cant > itemStore.Qty) {
                        needUpdateUi = true;
                        state.items_in_map.delete(key);
                    }
                }
            }
            if (needUpdateUi)
                state.items_in_car = Array.from(state.items_in_map.values());
            if (state.carOrderTemplate && state.carOrderTemplate.fields) {
                for (let soRow of state.carOrderTemplate.fields.Items) {
                    let itemStore = state.items_store.get(soRow.fields.ArtCode);
                    if (!itemStore || soRow.fields.Qty > itemStore.Qty) {
                        needUpdateUi = true;
                        state.carOrderTemplate.fields.Items = state.carOrderTemplate.fields.Items.filter(x => x.fields.ArtCode != soRow.fields.ArtCode);
                    }
                }
                if (state.carOrderTemplate.fields.Items.length === 0)
                    state.carOrderTemplate = null;
            }
            if (needUpdateUi)
                state.carFlag = Math.floor((Math.random() * 1000000) + 1);
        }
    },
    clearSalesOrderFields(state) {
        state.order_Office = null;
        state.order_DelAddress = null;
        state.order_DelCity = null;
        state.order_DelProvinceCode = null;
        state.order_DelCountry = null;
        state.oorder_DelLocalityCode = null;
        state.order_DeliveryDate = moment().format('YYYY-MM-DD');
        state.order_DelDay = null;
        state.order_DelDay = null;
        state.order_DelHour = null;

        state.order_PayTerm = null;
        state.order_ShipMode = null;
        state.order_DelZipCode = null;
        state.order_Comment = null;
        state.order_PayTerm = null;
        state.order_CenterCost = null;
        state.order_ReceptionManager = null;
        state.order_Campaign = null;
    },
    changeCenterCostRequire(state, requireCC) {
        state.requireCenterCost = requireCC;
    },
    updateDaysByZone(state, daysByZone) {
        state.daysByZone = daysByZone;
    },
    updateLockScreen(state, lockStatus) {
        state.screenState = lockStatus.visible;
        if (lockStatus.msg)
            state.screenMsg = lockStatus.msg;
        else
            state.screenMsg = '';
    },
    updateHomeSections(state, newHomeSections) {
        state.homeSections = newHomeSections;
    },
    resetState(state) {
        state = defaultState;
        window.runCustomSettings();
    },
    updateItemsIsLoaded(state, newStatus) {
        state.itemsFullLoader = newStatus;
    },
    eventIOItem(state, event) {
        //console.log(event.value);
        switch (event.action) {
            case 'updateStore':
                if (state.items_store.has(event.value.Code)) {
                    /*state.items = state.items.filter(x => x.Code != event.value);
                        state.items_visible = state.items_visible.filter(x => x.Code != event.value);*/
                    state.items_store.set(event.value.Code);
                }
                //store.state.items_store.set(event.value.Code)
                break;
            case 'deleteFromStore':
                state.items = state.items.filter(x => x.Code != event.value);
                state.items_visible = state.items_visible.filter(x => x.Code != event.value);
                state.items_store.delete(event.value);
                break;
        }
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updateOrderView(state, newState) {
        state.orderView = newState;
    },
    updateRedirectLogin(state, redirectPath) {
        state.redirectAfterLogin = redirectPath;
    },
    updatePreserveStatus(state, perserveStatus) {
        state.carPreserveInfo = perserveStatus;
    },
    updateSocketReady(state, socketStatus) {
        state.socketReady = socketStatus;
    },
    updateOrderDeliveryData(state, newData) {
        Object.assign(state, newData);
    },
    updateOrderCenterCost(state, newCenterCost) {
        state.order_CenterCost = newCenterCost;
    },
    updateSelectLinkto(state, obj) {
        let selectLinkToOptionsNew = Object.assign({}, state.selectLinkToOptions);
        selectLinkToOptionsNew[obj.table] = obj.value;
        state.selectLinkToOptions = selectLinkToOptionsNew;
        //console.log(state.selectLinkToOptions, 'mutation selectLinktoOption')
        /*let i = state.selectLinkToOptions.findIndex(x => x[0] === obj[0])
         if(i !== -1)
         state.selectLinkToOptions[i][1]=obj[1] //actualizo valor
         else
         state.selectLinkToOptions.push(obj)  */

    },
    /* resetSelectLinkto(state) {
        state.selectLinkToOptions = newValue;
    },*/
    setOrderSelected(state, newField) {
        state.orderSelected = newField;
    },
    setOrderBy(state, newField) {
        state.orderBy = newField;
    },
    setOrderDirection(state, newField) {
        state.orderDirection = newField;
    },
    setLanguage(state, newDict) {
        state.translateDict = Object.freeze(newDict);
    },
    updateCarAmount(state, getters) {
        if (state.carOrderTemplate && state.carOrderTemplate.fields) {
            if (state.setting.ShowPriceWithIVA)
                state.total_in_car = state.carOrderTemplate.fields.Total;
            else
                state.total_in_car = state.carOrderTemplate.fields.SubTotal;
        } else {

            const getItemPrice = function (item, getters) {
                let price = item.Price;
                if (getters.getPriceToShow)
                    price = item.RowTotal;
                else {
                    if (item.RowNet)
                        price = item.RowNet;
                }
                if (Number.isNaN(price) || price === null)
                    price = 0;
                let priceResult = parseFloat(Math.round(parseFloat(price) * 100) / 100).toFixed(2);
                //console.log( 'ItemPrice Result',priceResult,price );
                return priceResult;
            };

            let carAmount = 0;
            for (let item of state.items_in_car) {
                let itemStore = state.items_store.get(item.id);
                if (!itemStore && !item.giftcardCode) {
                    itemStore = item.item;
                    itemStore.message = "Item Removed";
                }
                if (item.giftcardCode) {
                    carAmount = carAmount + parseFloat(item.amount);
                } else {
                    if (isNaN(item.cant))
                        item.cant = 0;
                    carAmount = carAmount + parseFloat(item.cant) * parseFloat(getItemPrice(itemStore, getters));
                }
            }
            if (state.total_in_car != carAmount)
                state.total_in_car = carAmount;
        }
    },
    resetItemToCar(state, status) {
        state.carOrderTemplate = null;
        state.items_in_map = new Map();
        state.items_in_car = [];
        state.total_in_car = 0;
        state.cartStatus = status;
        state.carFlag = Math.floor((Math.random() * 1000000) + 1);
        wrapPost(`/${state.setting.PathURL}/api/saveCarSession`, {
            state: state.appState,
            from: 'resetItemToCar',
            'items': JSON.stringify(state.items_in_car)
        });
    },
    clearItemToCar(state, newStatus) {
        if (newStatus)
            state.cartStatus = newStatus;
        if (newStatus === 'result' || newStatus === 'clear') {
            state.carOrderTemplate = null;
            state.items_in_map = new Map();
            state.items_in_car = [];
            state.total_in_car = 0;
            wrapPost(`/${state.setting.PathURL}/api/saveCarSession`, {
                state: state.appState,
                from: 'clearItemToCar',
                'items': JSON.stringify(state.items_in_car)
            });
        }
        state.carFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updateCarStatus(state, newStatus) {
        state.cartStatus = newStatus;
    },
    reloadCarPrice(state, clearCodes) {
        for (let itemCode of state.items_in_map.keys()) {
            if (state.items_store.has(itemCode) && clearCodes.indexOf(itemCode) === -1)
                state.items_in_map.get(itemCode).item = state.items_store.get(itemCode);
        }
    },
    updateSessionCar(state, itemCart) {
        for (let item of itemCart) {
            state.items_in_map.set(item.uniqueId, item);
        }
        state.items_in_car = Array.from(state.items_in_map.values());
        state.carFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updateItemToCar(state, item) {
        let formaterQty;
        let storeItem = state.items_store.get(item.Code);
        let carRow = {
            id: item.Code,
            uniqueId: item.Code,
            cant: null,
            item: item,
            message: null,
            variant: item.Variants != undefined,
            useQtyFormula: state.useOrderQtyFormula && state.orderQtyFormulaFields.length > 0,
        };
        if (state.useOrderQtyFormula && state.orderQtyFormulaFields.length > 0) {
            let rowCode = item.Code;
            for (let formulaField of state.orderQtyFormulaFields) {
                console.log(carRow, item, carRow[formulaField], item[formulaField]);
                carRow[formulaField] = item[formulaField];
                rowCode += '*-*' + formulaField + '*-*' + item[formulaField];
            }
            carRow.uniqueId = rowCode;
            state.items_in_map.set(rowCode, carRow);
        } else {
            let itemCar = state.items_in_map.get(item.Code);
            if (storeItem && storeItem.OnlyIntegerValues === 1) {
                formaterQty = parseInt(item.carQty);
            } else {
                formaterQty = Math.round((parseFloat(item.carQty) + Number.EPSILON) * 100) / 100;
            }
            if (itemCar) {
                let formaterCarQty;
                if (itemCar.OnlyIntegerValues === 1) {
                    formaterCarQty = parseInt(itemCar.cant);
                } else {
                    formaterCarQty = Math.round((parseFloat(itemCar.cant) + Number.EPSILON) * 100) / 100;
                }
                if (formaterQty) {
                    state.items_in_map.get(item.Code).cant = formaterCarQty + formaterQty;
                }
            } else {
                carRow.cant = formaterQty;
                state.items_in_map.set(item.Code, carRow);
            }
        }
        state.items_in_car = Array.from(state.items_in_map.values());
        item.carQty = 0;
        state.carFlag = Math.floor((Math.random() * 1000000) + 1);
        if (state.session)
            saveCarSession(state.appState, 'updateItemToCar', JSON.stringify(state.items_in_car));
    },
    updateCarQty(state, params) {
        switch (params.action) {
            case 'up':
                state.items_in_map.get(params.itemCode).cant = state.items_in_map.get(params.itemCode).cant + 1;
                break;
            case 'down':
                if (state.items_in_map.get(params.itemCode).cant > 0)
                    state.items_in_map.get(params.itemCode).cant = state.items_in_map.get(params.itemCode).cant - 1;
                break;
            case 'delete':
                state.items_in_map.delete(params.itemCode);
                break;
            case 'update':
                if (state.items_in_map.get(params.itemCode) && params.qty)
                    state.items_in_map.get(params.itemCode).cant = params.qty;
        }
        state.items_in_car = Array.from(state.items_in_map.values());
        saveCarSession(state.appState, 'updateCarQty', JSON.stringify(state.items_in_car));
    },
    updateIsLogin(state, SessionisLogin) {
        state.isLoggin = SessionisLogin;
    },
    clearCustomerPersons(state) {
        state.CustomerPersons.length = 0;
    },
    loadCustomerPersons(state, persons) {
        state.CustomerPersons = persons;
    },
    addCustomerPerson(state, person) {
        if (!state.CustomerPersons.includes(person)) {
            state.CustomerPersons.push(person);
            //console.log(state.CustomerPersons,'state.CustomerPersons')
        }

    },
    updateCustomerPerson(state, Person) {
        let cloneCustomerPersons = _.cloneDeep(state.CustomerPersons);
        if (cloneCustomerPersons.findIndex(x => x.Code === Person.Code) !== -1) {
            cloneCustomerPersons[cloneCustomerPersons.findIndex(x => x.Code === Person.Code)] = Person;
            state.CustomerPersons = cloneCustomerPersons;
        }
    },
    //TODO esto estaba cuando puse cerrar registro persona, campo close en true , q lo eliminaba del state
    /*CloseCustomerPerson(state, personCode) {
        let cloneCustomerPersons = _.cloneDeep(state.CustomerPersons)
        if (cloneCustomerPersons.findIndex(x => x.Code === personCode) !== -1) {
            cloneCustomerPersons.splice(cloneCustomerPersons.findIndex(x => x.Code === personCode), 1);
            state.CustomerPersons = cloneCustomerPersons;
        }
    },*/

    clearCustomerAddresses(state) {
        state.CustomerAddresses.length = 0;
    },
    loadCustomerAddresses(state, Addresses) {
        state.CustomerAddresses = Addresses;
    },
    AddAddressCustomer(state, address) {
        if (!state.CustomerAddresses.includes(address)) {
            state.CustomerAddresses.push(address);
        }
    },

    updateAddressCustomer(state, address) {
        let cloneCustomerAddresses = _.cloneDeep(state.CustomerAddresses);
        let indexAddress = cloneCustomerAddresses.findIndex(x => x.Code === address.Code);
        if (indexAddress !== -1) {
            console.log("Address", address);
            cloneCustomerAddresses[indexAddress] = address;
            console.log("Address", address);
            state.CustomerAddresses = cloneCustomerAddresses;
        }
    },

    DisableAddress(state, addressCode) {
        let cloneCustomerAddresses = _.cloneDeep(state.CustomerAddresses);
        if (cloneCustomerAddresses.findIndex(x => x.Code === addressCode) !== -1) {
            cloneCustomerAddresses.splice(cloneCustomerAddresses.findIndex(x => x.Code === addressCode), 1);
            state.CustomerAddresses = cloneCustomerAddresses;
        }
    },

    addFilter(state, newFilter) {
        state.filtersObjects.set(newFilter.name, newFilter);
        state.filters.push(newFilter.name);
    },
    removeFilter(state, newFilter) {
        state.filtersObjects.delete(newFilter.name);
        state.filters.pop();
    },
    updateSetting(state, newValue) {
        state.setting = newValue;
        if (newValue.ItemViewMode)
            state.defaultItemSize = newValue.ItemViewMode;

        for (let orderField of newValue.ItemOrdered) {
            if (orderField.fields.ByDefault) {
                //console.log(orderField)
                state.orderSelected = orderField.fields.internalId;
                state.orderBy = orderField.fields.ItemField;
                state.orderDirection = orderField.fields.OrderDirection;
            }
        }
    },
    updateSession(state, newSession) {
        state.session = newSession;
    },
    updateGenericUser(state, newValue) {
        state.mustUpdateInfo = newValue;
        //console.log('Updates Generic User', state.mustUpdateInfo, newValue);
    },
    updateSalesMan(state, salesMan) {
        state.salesman = salesMan;
    },
    updateCustomer(state, customer) {
        state.customer = customer;
    },
    updateCurrentOrder(state, newOrder) {
        state.currentOrder = newOrder;
    },
    updateCurrentFavOrder(state, newOrder) {
        state.currentFavOrder = newOrder;
    },
    updateCurrentCustomerPerson(state, CustomerPerson) {
        state.currentCustomerPerson = CustomerPerson;
    },
    updateIsLiberatorCenterCost(state, CenterLiberators) {
        state.CenterLiberators = CenterLiberators;
    },
    updateIsOnlyModifierCenterCost(state, CenterOnlyModifiedCodes) {
        state.CenterOnlyModifiedCodes = CenterOnlyModifiedCodes;
    },
    updateIsLiberatorOrder(state, isLiberator) {
        if (Array.isArray(isLiberator)) {
            state.liberatorCodes = isLiberator;
            state.order_can_liberate = isLiberator.length > 0;
        } else
            state.order_can_liberate = isLiberator;
    },
    updateIsOnlyModifier(state, isModifier) {
        if (Array.isArray(isModifier)) {
            state.onlyModifiedCodes = isModifier;
            state.isOnlyModified = isModifier.length > 0;
        } else
            state.isOnlyModified = isModifier;
    },
    updateIsAdmin(state, isAdmin) {
        state.isAdmin = isAdmin;
    },
    updateOrderList(state, newValue) {
        state.order_list = newValue;
    },
    updateOrderTemplate(state, orderTemplate) {
        state.orderTemplate = orderTemplate;
    },
    updateOrderEditableStates(state, orderStates) {
        state.order_editable_states = orderStates;
    },
    updateVatCode(state, vatCodeList) {
        for (let vatCode of vatCodeList)
            state.vatCodeMap.set(vatCode.Code, vatCode);
    },
    updateTranslate(state, newDict) {
        state.translateDict = newDict;
    },
    updatePriceDeal(state, priceDeal) {
        state.priceDeal = priceDeal;
    },
    updateDiscountDeal(state, discountDeal) {
        state.discountDeal = discountDeal;
    },
    updatesItemsStore(state, items) {
        //console.log('items in session',Object.freeze(items));
        state.items_store = ArrayToMap(items, 'Code');
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updatesHomeItemsStore(state, items) {
        //console.log('items in session',Object.freeze(items));
        state.itemInHome = ArrayToMap(items, 'Code');
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updateItemsInStore(state, itemList) {
        for (let item of itemList)
            state.items_store.set(item.Code, item);
    },
    toogleCarModal(state) {
        state.show_CarModal = !state.show_CarModal;
    },
    addFavorite(state, itemCode) {
        //console.log( "mutacion favorites",itemCode );
        if (!state.favorites.includes(itemCode)) {
            state.favorites.push(itemCode);
        }
    },
    removeFavorite(state, itemCode) {
        let newFavorites = [];
        for (let fav of state.favorites)
            if (fav != itemCode)
                newFavorites.push(fav);
        //console.log('New',newFavorites);
        state.favorites = newFavorites;
    },
    addFavouritesOrders(state, e) {
        if (!state.favouritesOrders.includes(e))
            state.favouritesOrders.push(e);
        //console.log( state.favouritesOrders,state.favouritesOrders.includes( e ) );
    },
    removeFavouritesOrders(state, e) {
        if (state.favouritesOrders.findIndex(x => x[0] === e[0]) !== -1)
            state.favouritesOrders.splice(state.favouritesOrders.findIndex(x => x[0] === e[0]), 1);
    },
    updateFavoriteOrderItemQty(state, FavOrder) {
        //console.log( FavOrder, 'FavOrder to APP' );

        //console.log( state.currentFavOrder, 'currentFavOrder IN APP' );
    },
    clearCurrentFavOrder(state) {
        state.currentFavOrder = null;
    },
    updatesCatList(state, categories) {
        state.cat_list = categories;
    },
    addItemsList(state, newItems) {
        if (Array.isArray(newItems)) {
            state.pageOffset = 0;
            state.items = state.items.concat(newItems);
        } else
            console.log('Error Type is not Array', newItems);
        state.items_visible = __lodash__.orderBy(state.items.filter(x => x.visible), state.orderBy, state.orderDirection);
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updateItemByCode(state, itemObject) {
        state.items_store.set(itemObject.code, itemObject.item);
        //console.log('updateItemByCode')
    },
    updatesItemsList(state, newItems) {
        //console.log( 'Muting Items List', newItems );
        if (Array.isArray(newItems)) {
            state.pageOffset = 0;
            state.items = newItems;
        } else
            console.log('Error Type is not Array', newItems);

        state.items_visible = __lodash__.orderBy(state.items.filter(x => x.visible), state.orderBy, state.orderDirection);
        state.itemUpdate = Math.floor((Math.random() * 1000000) + 1);
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    clearItemList(state) {
        state.items = [];
        state.items_visible = [];
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updateItemFlag(state) {
        state.itemUpdate = Math.floor((Math.random() * 1000000) + 1);
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updateItemItemFlag(state, newValue) {
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updateAppState(state, newState) {
        state.appState = newState;
    },
    updateCategoryMap(state, catList) {
        let nameMap = new Map();
        let codeMap = new Map();
        for (let cat of catList) {
            if (!nameMap.has(cat.name))
                nameMap.set(cat.name, cat);
            if (!codeMap.has(cat.code))
                codeMap.set(cat.code, cat);
        }
        state.cat_by_name = Object.freeze(nameMap);
        state.cat_by_code = Object.freeze(codeMap);
    },
    updateCategory(state, category) {
        let history = [];
        if (category) {
            GetCategoryPath(category.code, state.cat_list[0], history);
        }
        state.cat_selected = category;
        state.cat_history = history.reverse();
    },
    updateCategoryHistory(state, parameters) {
        //console.log('History TEst',parameters)
        if (parameters.temp) {
            state.cat_history_temp = state.cat_history_temp.filter((x) => {
                return x.lvl < parameters.lvl;
            });
            parameters.category.lvl = parameters.lvl;
            state.cat_history_temp.push(parameters.category);
        } else {
            state.cat_history = state.cat_history_temp;
        }
    },
    updateGifts(state, customerGifts) {
        state.customerGifts = customerGifts;
    },
    updateSuggestions(state, suggestions) {
        state.suggestionList = suggestions;
    },
    restoreHistory(state) {
        state.cat_history = [];
        state.cat_history_temp = [];
    },
    restoreFilter(state) {
        state.filters = [];
        state.filtersObjects = new Map();
    },
    updateOurSettingsCountry(state, Country) {
        state.OurSettingsCountry = Country;
    },
    updateGiftCardItem(state, Code) {
        state.giftCardItem = Code;
    },
    updateGiftCardApplied(state, rec) {
        state.giftCardApplied = rec;
    },
    updateCampaignApplied(state, rec) {
        state.campaignApplied = rec;
    }
};

window.vuexModules = {};

// eslint-disable-next-line no-unused-vars
function loadDinamicRouters(settings) {
    console.log('Standard Router Settings', settings);
    for (let dynamicPage of settings.fields.DynamicPages) {
        if (dynamicPage.fields.Status)
            globalRouter.splice(globalRouter.length - 2, 0, {
                name: `TemplatePageRoute${dynamicPage.fields.PagePath}`,
                path: `/${dynamicPage.fields.PagePath}`,
                component: {template: `<templatePage templateCode="${dynamicPage.fields.HTMLTemplate}" />`}
            });
    }
    return null;
}

function runCustomSettings() {
    console.log('Standard Custom Settings');
    return null;
}

window.runCustomSettings = runCustomSettings;
